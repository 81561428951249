import { Stack, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { ModalParagraph } from './shared';

const ServiceTerms = () => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('ServiceTerms.title')}>
      <Stack>
        <Text as="span" weight="medium">
          {t('ServiceTerms.subTitle')}
        </Text>
        <Stack>
          {Array.from({ length: 12 }, (_, i) => (
            <ModalParagraph key={i}>{t(`ServiceTerms.term${i + 1}`)}</ModalParagraph>
          ))}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ServiceTerms;
