import groq from 'groq';
import { CountryConfig } from '../../../localization/constants';
import { environment } from '../../../utils/env.utils';
import { sanityFetch } from '../client';
import { TERMS_AND_CONDITIONS_QUERYResult } from '../sanity.types';
import { CONTENT_BLOCK_GROUP_PROJECTION } from './projections';

const TERMS_AND_CONDITIONS_QUERY = groq`*[_type == "termsContent" && locale == $locale][0] {
  id,
  _type,
  lastUpdatedTitle,
  contentBlockGroup ${CONTENT_BLOCK_GROUP_PROJECTION} 
}`;

export const getTermsAndConditions = () =>
  sanityFetch<TERMS_AND_CONDITIONS_QUERYResult>(TERMS_AND_CONDITIONS_QUERY, {
    locale: CountryConfig[environment.country].locale
  });
