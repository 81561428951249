import { Stack } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../api/utils/queryKeys';
import { cmsApi } from '../../../integrations/sanity/api';
import { PortableTextContent } from '../../shared';
import Modal from '../Modal';
import { TermsHeadingText } from './shared';

const BodyContent = styled.div`
  strong {
    font-weight: bold;
  }

  & > * + * {
    margin-top: 8px;
  }
`;

const TermsAndConditionsModal = () => {
  const { t } = useTranslation(['Common']);
  const { data: termsAndConditionsData } = useQuery({
    queryKey: queryKeys.cms.termsAndConditions,
    queryFn: cmsApi.getTermsAndConditions,
    staleTime: Infinity
  });

  if (!termsAndConditionsData) {
    return null;
  }

  return (
    <Modal title={t('termsAndConditionsFairloEverydayLoans')}>
      <Stack>
        {termsAndConditionsData?.contentBlockGroup?.contentBlocks?.map(contentBlock => (
          <div key={contentBlock._key}>
            <Stack spacing={16}>
              <TermsHeadingText as="h4">{contentBlock.title}</TermsHeadingText>
              <BodyContent>
                {!!contentBlock.body && <PortableTextContent value={contentBlock.body} />}
              </BodyContent>
            </Stack>
          </div>
        ))}
      </Stack>
    </Modal>
  );
};

export default TermsAndConditionsModal;
