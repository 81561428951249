import { AnchorButton, List, ListItem, ListItemText, Stack } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { QueryParam } from '../../../constants';
import { routeMap } from '../../../routes/routeMap';
import { FullWidthContainer } from '../../styled';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';
import { Link } from 'react-router-dom';

type Payload = { creditDebt: number; debtConsolidationDebt: number };
type Props = ModalProps<Payload>;

function RemainingDebtInfo({ payload: { creditDebt, debtConsolidationDebt } }: Props) {
  const { t } = useTranslation(['Modal', 'Common']);
  const hasCreditDebt = creditDebt > 0;
  const hasDebtConsolidationDebt = debtConsolidationDebt > 0;

  let linkToDeposit = {
    pathname: routeMap.credit.deposit.root,
    search: `${QueryParam.DEPOSIT_AMOUNT}=${creditDebt}`
  };
  if (!hasCreditDebt && hasDebtConsolidationDebt) {
    linkToDeposit = {
      pathname: routeMap.debtConsolidation.deposit,
      search: `${QueryParam.DEPOSIT_AMOUNT}=${debtConsolidationDebt}`
    };
  }

  return (
    <Modal title={t('RemainingDebtInfo.title')}>
      <Stack>
        <ModalParagraph>{t('RemainingDebtInfo.info')}</ModalParagraph>
        <ModalParagraph>{t('RemainingDebtInfo.infoSecond')}</ModalParagraph>
        <div>
          {!!hasCreditDebt && (
            <FullWidthContainer>
              <List>
                <ListItem>
                  <ListItemText text={t('RemainingDebtInfo.creditProduct')} />
                  <ListItemText text={t('Common:money', { amount: creditDebt })} />
                </ListItem>
              </List>
            </FullWidthContainer>
          )}
          {!!hasDebtConsolidationDebt && (
            <FullWidthContainer>
              <List>
                <ListItem>
                  <ListItemText text={t('RemainingDebtInfo.debtConsolidationProduct')} />
                  <ListItemText text={t('Common:money', { amount: debtConsolidationDebt })} />
                </ListItem>
              </List>
            </FullWidthContainer>
          )}
        </div>
      </Stack>
      <Modal.Actions>
        <AnchorButton as={Link} to={linkToDeposit}>
          {t('RemainingDebtInfo.action')}
        </AnchorButton>
      </Modal.Actions>
    </Modal>
  );
}

export default RemainingDebtInfo;
