import { z } from 'zod';
import { ErrorId, ProductName } from '../../constants';
import { ApiError } from '../types';
import { getWithdrawalResponseSchema } from './schemas';

export type RedirectUrls = {
  successfulUrl: string;
  failedUrl: string;
};

export type SEDepositPayload = RedirectUrls & {
  amount: number;
  productName: ProductName;
};

export type UKDepositPayload = {
  amount: number;
  productName: ProductName;
};

export type DepositResponse = {
  paymentProviderUrl: string;
};

export type SEPayOverduePayload = RedirectUrls & {
  amount: number;
};

export type UKPayOverduePayload = {
  amount: number;
};

export type PayOverdueResponse = {
  paymentProviderUrl: string;
};

export type PendingTransactionResponse = {
  pendingTransaction: boolean;
  delayed: boolean;
  type: string;
};

export enum WithdrawBlockedReason {
  IP_ADDRESS_CHECK = 'IpAddressCheck',
  BLOCKED = 'Blocked'
}

type SEWithdrawError = ApiError<
  | ErrorId.INSUFFICIENT_FUNDS
  | ErrorId.DIRECT_DEBIT_CHARGE_IN_PROGRESS
  | ErrorId.OVERDUE_PAYBACKS_CHARGE_IN_PROGRESS
  | ErrorId.DIRECT_DEBIT_MANDATE_NOT_FOUND
  | ErrorId.WITHDRAWAL_NOT_ALLOWED_PAST_COLLECTION_FEE
  | ErrorId.WITHDRAW_NOT_APPROVED,
  { message: WithdrawBlockedReason }
>;

type UKWithdrawError = ApiError<
  | ErrorId.DIRECT_DEBIT_CHARGE_IN_PROGRESS
  | ErrorId.OVERDUE_PAYBACKS_CHARGE_IN_PROGRESS
  | ErrorId.DIRECT_DEBIT_MANDATE_NOT_FOUND
>;

export type WithdrawError = SEWithdrawError | UKWithdrawError;

export enum DepositType {
  PAY_EXTRA = 'PAY_EXTRA',
  OVERDUE = 'OVERDUE',
  COMPLETE_LOAN = 'COMPLETE_LOAN'
}

export type WithdrawResponse = {
  transactionId: string;
};

export type GetWithdrawalResponse = z.infer<typeof getWithdrawalResponseSchema>;
