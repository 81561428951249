import React, { Suspense } from 'react';

import { useIsDevCycleInitialized, useVariableValue } from '@devcycle/react-client-sdk';
import Layout from '../../components/Layout';
import Loader from '../../components/Loader';

import { environment } from '../../utils/env.utils';

const PagesComponent = React.lazy(() => import(`./country/${environment.country}.tsx`));
const Maintenance = React.lazy(() => import('../../pages/Maintenance'));

const Pages = () => {
  const isMaintenanceInProgress = useVariableValue('maintenance-in-progress', false);
  if (!useIsDevCycleInitialized()) {
    return <Loader />;
  }

  if (isMaintenanceInProgress) {
    return (
      <Suspense fallback={<Loader />}>
        <Maintenance />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <Layout>
        <PagesComponent />
      </Layout>
    </Suspense>
  );
};

export default Pages;
