import { Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { ModalListItem, ModalParagraph, TermsHeadingText } from '../shared';
import { ModalProps } from '../types';

const PaymentProtectionInsuranceTerms = ({ closeModal, confirmAction = closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button']);

  return (
    <Modal title="Krav för betalskyddet">
      <ModalParagraph>Fairlo Betalskydd passar dig som är privatperson och som:</ModalParagraph>
      <ul>
        <ModalListItem>Har en aktiv kontokredit hos Fairlo utan likvärdigt betalskydd.</ModalListItem>
        <ModalListItem>Är stadigvarande bosatt i Sverige vid tecknandet av försäkringen.</ModalListItem>
        <ModalListItem>Uppfyller någon av nedananstående:</ModalListItem>
      </ul>
      <TermsHeadingText>För anställda</TermsHeadingText>
      <ul>
        <ModalListItem>
          Du har en tillsvidareanställning och denna har pågått i minst tre sammanhängande månader före
          tecknandet av försäkringen, utan att det föreligger en verkställd uppsägning.
        </ModalListItem>
        <ModalListItem>
          Det föreligger ingen varsel om permittering eller verkställd permittering.
        </ModalListItem>
        <ModalListItem>
          Din arbetsgivare tar inte emot statligt stöd för anställningen enligt lag (2013:948) om stöd vid
          korttidsarbete.
        </ModalListItem>
        <ModalListItem>
          Du är fullt arbetsför och får inte sjukpenning, sjukersättning, a-kassa, aktivitetsersättning eller
          liknande.
        </ModalListItem>
        <ModalListItem>
          Du är inskriven i svensk försäkringskassa och ansluten till en erkänd svensk a-kassa.
        </ModalListItem>
      </ul>
      <TermsHeadingText>För egenföretagare</TermsHeadingText>
      <ul>
        <ModalListItem>Du driver eget företag.</ModalListItem>
        <ModalListItem>
          Du är fullt arbetsför och tar inte emot sjukpenning, sjukersättning, a-kassa, aktivitetsersättning
          eller liknande.
        </ModalListItem>
        <ModalListItem>
          Du är inte inlagd på sjukhus eller vistas på annan ort till följd av läkares inrådan.
        </ModalListItem>
      </ul>
      <TermsHeadingText>För pensionärer</TermsHeadingText>
      <ul>
        <ModalListItem>Du är ålderspensionär, förtidspensionär eller sjukpensionär.</ModalListItem>
        <ModalListItem>
          Du är inte inlagd på sjukhus eller vistas på annan ort till följd av läkares inrådan.
        </ModalListItem>
      </ul>

      <Modal.Actions>
        <Button onClick={confirmAction}>{t('Button:close')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PaymentProtectionInsuranceTerms;
