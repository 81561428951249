import groq from 'groq';
import { environment } from '../../../utils/env.utils';
import { sanityFetch } from '../client';
import { NOTICES_QUERYResult } from '../sanity.types';

const NOTICES_QUERY = groq`*[_type == 'notice' && country == $country]{
  _id,
  title,
  content,
  severity,
  pagesShown
}`;

export const getNotices = () =>
  sanityFetch<NOTICES_QUERYResult>(NOTICES_QUERY, { country: environment.country });
