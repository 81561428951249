import { Accordion, device, Heading } from '@fairlo/ui-kit';
import styled, { css } from 'styled-components';

export const TermsStyledHeading = styled(Heading)`
  font-weight: bold;
`;

export const modalAccordionStyles = css`
  margin-left: calc(-1 * var(--modal-horizontal-padding));
  margin-right: calc(-1 * var(--modal-horizontal-padding));
  margin-bottom: calc(-1 * var(--modal-vertical-padding));

  @media (${device.tablet}) {
    margin: 0;
  }
`;

export const ModalAccordion = styled(Accordion)`
  ${modalAccordionStyles};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border: 1px solid black;
  }
`;
