import { z } from 'zod';

export const promotionsResponseSchema = z.object({
  paymentProtectionInsurance: z.object({
    active: z.boolean()
  }),
  levla: z.object({
    active: z.boolean()
  })
});
