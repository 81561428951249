import { useCallback } from 'react';
import { useCookie } from 'react-use';
import { cookies } from '../../../../../../constants/cookies';
import { getCookieValue } from '../../../../../../utils/cookies';

const { name, expires } = cookies.paymentProtectionInsuranceModal;

const getHasCooldownPeriod = () => getCookieValue(name) === 'true';

const usePPIPromoModalCooldownPeriod = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setValue] = useCookie(name);

  const setCooldownPeriod = useCallback(() => {
    setValue('true', { expires });
  }, [setValue]);

  return {
    getHasCooldownPeriod,
    setCooldownPeriod
  };
};

export default usePPIPromoModalCooldownPeriod;
