import { AnchorButton, Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import marketingSiteRouteMap from '../../../constants/marketingSiteRouteMap';
import useWithdrawMutation from '../../../pages/Products/components/features/GenericWithdraw/hooks/useWithdrawMutation';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

type Payload = { amount: number };
type Props = ModalProps<Payload>;

const WithdrawalFail = ({ closeModal, payload }: Props) => {
  const { t } = useTranslation(['Modal', 'Common', 'Button']);
  const { mutate: withdraw } = useWithdrawMutation();
  const { amount } = payload;

  const handleClick = () => {
    withdraw(amount);
    closeModal();
  };

  return (
    <Modal title={t('WithdrawalFail.title')}>
      <ModalParagraph>{t('WithdrawalFail.info')}</ModalParagraph>
      <Modal.Actions>
        <AnchorButton href={marketingSiteRouteMap.contact} variant="outlined" color="neutral">
          {t('Common:contactUs')}
        </AnchorButton>
        <Button onClick={handleClick}>{t('Button:tryAgain')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WithdrawalFail;
