export enum QueryParam {
  AUTH_TOKEN = 'auth_token',
  WITHDRAW_ACTION = 'withdrawAction',
  ACCOUNT_UPDATE_FAILED = 'accountUpdateFailed',
  DEPOSIT_AMOUNT = 'depositAmount',
  MONTHLY_AMOUNT = 'monthlyAmount',
  IS_MANUAL_DEPOSIT = 'isManualDeposit',
  DEPOSIT_TYPE = 'depositType',
  SELECT_MAX_AMOUNT = 'selectMaxAmount',
  STATUS = 'status',
  AGREEMENT_ID = 'agreementId',
  TESTING_AMOUNT = 'testingAmount',
  REDIRECT_TO = 'redirectTo',
  SELECT_ACCOUNT_REDIRECT = 'selectAccountRedirect',
  DIRECT_PAYMENT_ID = 'directPaymentId',
  PROVIDER_CALLBACK = 'providerCallback',
  APPLICATION_ID = 'applicationId',
  PAYMENT_ORIGIN = 'paymentOrigin',
  RETRY = 'retry',
  TOKEN = 'token',
  AUTO_START_TOKEN = 'autoStartToken',
  NATIONAL_NUMBER = 'nationalNumber'
}

export enum QueryParamValue {
  DEPOSIT_SUCCESS = 'deposit-success',
  DEPOSIT_FAIL = 'deposit-fail',
  WITHDRAW_ALL = 'withdrawAll',
  SELECT_MAX_WITHDRAWAL = 'selectMaxWithdrawal',
  PAY_OVERDUE_SUCCESS = 'pay-overdue-success',
  PAY_OVERDUE_FAIL = 'pay-overdue-fail'
}
