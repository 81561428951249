export const redactedValue = '********';

export const sensitiveDataKeys = [
  'email',
  'phone',
  'firstName',
  'lastName',
  'givenName',
  'fullName',
  'userName',
  'address',
  'postalCode',
  'postCode',
  'personalNumber',
  'nationalNumber',
  'mobile',
  'birth',
  'town',
  'city',
  'street',
  'buildingNumber',
  'buildingName',
  'accountNumber',
  'sortCode',
  'authToken'
];

const sensitiveDataRegExp = new RegExp(sensitiveDataKeys.join('|'), 'i');

type MaskedObject<ObjectType extends Record<string, unknown>> = {
  [Key in keyof ObjectType]: ObjectType[Key] extends Record<string, unknown>
    ? MaskedObject<ObjectType[Key]> | string
    : ObjectType[Key] | string;
};

/**
 * Masks sensitive data in an object by replacing values of keys that match a sensitive data pattern with '********'.
 * If a value is an object, the function is called recursively to mask sensitive data within nested objects.
 *
 * @example
 * maskSensitiveData({
 *  user: { email: 'john.doe@mail.com' },
 *  feedback: 'lorem ipsum',
 * })
 * -> { user: { email : '********' }, feedback: 'lorem ipsum' }
 */
export const maskSensitiveData = <T extends Record<string, unknown>>(object: T) => {
  const filteredObject: MaskedObject<T> = { ...object };

  for (const key in filteredObject) {
    if (sensitiveDataRegExp.test(key)) {
      filteredObject[key] = redactedValue;
    } else if (typeof filteredObject[key] === 'object') {
      // @ts-expect-error - Typescript won today
      filteredObject[key] = maskSensitiveData(filteredObject[key] as Record<string, unknown>);
    }
  }

  return filteredObject;
};
