import { useQuery } from '@tanstack/react-query';
import API, { PromotionsResponse } from '../../../../../../api';
import { queryKeys } from '../../../../../../api/utils/queryKeys';
import { CustomUseQueryOptions } from '../../../../../../types/reactQuery';

const usePromotionsQuery = <TData = PromotionsResponse>(
  options?: CustomUseQueryOptions<PromotionsResponse, TData>
) => useQuery({ queryKey: queryKeys.promotions, queryFn: API.promotions.getPromotions, ...options });

export default usePromotionsQuery;
