import { AnchorButton, Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { routeMap } from '../../../../routes/routeMap';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

const MigrationToNewTierNeeded = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Common']);

  return (
    <Modal title={t('MigrationToNewTierNeeded.title')}>
      <ModalParagraph>{t('MigrationToNewTierNeeded.info')}</ModalParagraph>
      <Modal.Actions>
        <AnchorButton as={Link} to={routeMap.credit.tierMigration.root} onClick={closeModal}>
          {t('MigrationToNewTierNeeded.readMore')}
        </AnchorButton>
        <Button variant="outlined" onClick={closeModal}>
          {t('Common:cancel')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default MigrationToNewTierNeeded;
