import axiosInstance from '../../utils/axiosInstance';
import { BankAccountResponse, GetPaymentResponse, PaymentByIdResponse, SetupAccountPayload } from './types';

export const setupAccount = async (payload: SetupAccountPayload) => {
  const response = await axiosInstance.post<BankAccountResponse>('/uk/payment-provider/account', payload);

  return response.data;
};

export const getBankAccount = async () => {
  const response = await axiosInstance.get<BankAccountResponse>('/uk/payment-provider/account');

  return response.data;
};

export const setRepaymentDay = async (repaymentDay: number) => {
  const response = await axiosInstance.patch<BankAccountResponse>(
    '/uk/payment-provider/account/repayment-day',
    { repaymentDay }
  );

  return response.data;
};

export const setupDirectDebit = async () => {
  const response = await axiosInstance.post<BankAccountResponse>(
    '/uk/payment-provider/account/direct-debit-setup'
  );

  return response.data;
};

export const initiateManualApproval = async () => {
  const response = await axiosInstance.post<''>('/uk/payment-provider/account/initiate-manual-approval');

  return response.data;
};

export const getPayment = async () => {
  const response = await axiosInstance.get<GetPaymentResponse>('/uk/payment-provider/payment');

  return response.data;
};

export const postManualPayment = async (payload: { amount: number }) =>
  axiosInstance.post<''>('/uk/payment-provider/payment/manual', payload);

export const getPaymentById = async (paymentInitiationId: string) => {
  const response = await axiosInstance.get<PaymentByIdResponse>(
    `/uk/payment-provider/payment/${paymentInitiationId}`
  );

  return response.data;
};
