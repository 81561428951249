export const flattenObject = (obj: Record<string, unknown>, parentKey = ''): Record<string, unknown> => {
  const flattened: Record<string, unknown> = {};

  Object.keys(obj).forEach(key => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      Object.assign(flattened, flattenObject(obj[key] as Record<string, unknown>, newKey));
    } else {
      flattened[newKey] = obj[key];
    }
  });

  return flattened;
};
