import { createClient, FilteredResponseQueryOptions } from '@sanity/client';
import { environment } from '../../utils/env.utils';
import { reportException } from '../../utils/error';

const { sanityProjectId, sanityDataset, sanityApiVersion, sanityApiToken, env } = environment;

const createSanityClient = async () => {
  let draftMode = false;
  if (['local', 'develop'].includes(env)) {
    const { useDevtoolsStore } = await import('../../store/devtoolsStore');
    const { cmsDraftMode } = useDevtoolsStore.getState();
    draftMode = cmsDraftMode;
  }

  return createClient({
    projectId: sanityProjectId,
    dataset: sanityDataset,
    apiVersion: sanityApiVersion,
    token: draftMode ? sanityApiToken : undefined,
    useCdn: !draftMode,
    perspective: draftMode ? 'previewDrafts' : 'published'
  });
};

export const sanityFetch = async <T>(
  query: string,
  params: Record<string, unknown> = {},
  options?: FilteredResponseQueryOptions
) => {
  const client = await createSanityClient();
  try {
    const result = await client.fetch<T>(query, params, options);
    return result;
  } catch (error) {
    reportException(error, { level: 'error', extra: { query, params, options } });
    throw error;
  }
};
