import { Colors, device, Text } from '@fairlo/ui-kit';
import styled from 'styled-components';

import { Container, zIndex } from '../../../../../styled';

export const MenuOverlay = styled.div`
  z-index: ${zIndex.HEADER - 1};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${Colors.GRAY_200};
`;

export const Wrapper = styled(Container)`
  padding: 0;
  z-index: ${zIndex.HEADER - 1}; // Needs to slide behind the header
  position: fixed;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  left: 0;

  @media (${device.tablet}) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    right: var(--scrollbar-width, 0);
  }
`;

export const Nav = styled.nav`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px;
  background-color: ${Colors.WHITE};
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: flex-end;
  align-items: stretch;

  @media (${device.tablet}) {
    position: static;
    width: 360px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
`;

export const Ul = styled.ul`
  // Reset ul styles
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;

  & > li {
    display: flex;
  }
`;

export const MenuText = styled(Text)`
  width: 100%;
  padding: 8px 0;
  text-decoration: none;
`;

export const Separator = styled.div`
  border-top: 1px solid ${Colors.GRAY_500};
`;
