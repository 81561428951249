import { AnchorButton, List, ListItem, ListItemText, Stack } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import { FullWidthContainer } from '../../styled';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';
import { Link } from 'react-router-dom';

type Payload = { monthlyPayment: number; redirectUrl: string };
type Props = ModalProps<Payload>;

const ChangeMonthlyPaymentSuccess = ({ payload }: Props) => {
  const { t } = useTranslation(['Modal', 'Button', 'Common']);
  const { monthlyPayment, redirectUrl } = payload;

  return (
    <Modal title={t('ChangeMonthlyPaymentSuccess.title')}>
      <Stack>
        <ModalParagraph>{t('ChangeMonthlyPaymentSuccess.info', { monthlyPayment })}</ModalParagraph>
        <FullWidthContainer>
          <List>
            <ListItem>
              <ListItemText text={t('ChangeMonthlyPaymentSuccess.listItemTitle')} />
              <ListItemText text={t('Common:money', { amount: monthlyPayment })} />
            </ListItem>
          </List>
        </FullWidthContainer>
      </Stack>
      <Modal.Actions>
        <AnchorButton as={Link} to={redirectUrl}>
          {t('Button:done')}
        </AnchorButton>
      </Modal.Actions>
    </Modal>
  );
};

export default ChangeMonthlyPaymentSuccess;
