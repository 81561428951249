import { Text } from '@fairlo/ui-kit';
import styled from 'styled-components';

import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import fairloLogoImage from '../../assets/images/fairlo-logo-gray.svg';
import Portal from '../Portal';
import { CenteredWrapper } from '../styled';

const StyledCenteredWrapper = styled(CenteredWrapper)`
  ${Text} {
    font-weight: 500;
    max-width: 440px;
    margin: 24px;
    text-align: center;
    white-space: pre-line;
  }
`;

const Image = styled.img`
  animation: bounce 1.5s infinite;

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
`;

type Props = {
  fullscreen?: boolean;
  text?: string;
  defaultText?: boolean;
};

const Loader = ({ defaultText = false, fullscreen = false, text }: Props) => {
  const { t } = useTranslation('Loader');
  const Wrapper = fullscreen ? Portal : Fragment;

  return (
    <Wrapper>
      <StyledCenteredWrapper $fullscreen={fullscreen} data-testid="loader-wrapper">
        <Image src={fairloLogoImage} alt="Fairlo Logo" width={100} />
        {!!(text || defaultText) && (
          <Text size="m" data-testid="message">
            {text ?? t('defaultMessage')}
          </Text>
        )}
      </StyledCenteredWrapper>
    </Wrapper>
  );
};

export default Loader;
