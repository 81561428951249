export enum ErrorId {
  INVALID_REQUEST = 3,
  AUTHENTICATION_FAILED = 10,
  RESOURCE_NOT_FOUND = 17,
  AGREEMENT_HAS_DEBT = 22,
  LAST_AGREEMENT_TERMINATED = 23,
  INSUFFICIENT_FUNDS = 25,
  USER_HAS_NO_ACTIVE_CREDIT = 26,
  WITHDRAW_NOT_APPROVED = 33,
  CONFLICT = 35,
  ACCESS_DENIED = 38,
  OVERDUE_PAYBACKS_CHARGE_IN_PROGRESS = 41,
  DIRECT_DEBIT_MANDATE_NOT_FOUND = 42,
  WITHDRAW_NOT_ALLOWED_USER_BLOCKED = 46,
  DEBT_TRANSFER_TIMEOUT = 48,
  DIRECT_DEBIT_CHARGE_IN_PROGRESS = 52,
  WITHDRAWAL_NOT_ALLOWED_PAST_COLLECTION_FEE = 54,
  TOO_SOON_ATTEMPT_TO_SEND_MESSAGE = 55,
  WRONG_CODE = 56,
  MAX_VERIFICATION_TRIES_REACHED = 57,
  APPLICATION_NOT_FOUND = 58,
  DOCUMENTS_NOT_SIGNED = 60,
  MOBILE_NOT_VERIFIED = 61,
  PRODUCT_TIER_NOT_SELECTED = 62,
  CODE_EXPIRED = 65,
  MALFORMED_CODE = 66,
  NO_MORE_VERIFICATION_ATTEMPTS_LEFT = 67,
  NO_APPROVED_APPLICATION = 70,
  MAX_ATTEMPTS_REACHED = 72,
  ACCOUNT_UNSUPPORTED = 73,
  UNABLE_TO_VALIDATE = 74,
  OLD_APPLICATION = 76,
  UNDER_MANUAL_APPROVAL = 79,
  USER_BLOCKED = 81,
  EMAIL_MISMATCH = 84,
  WAF_BLOCKED = 89,
  INTERNAL_SERVER_ERROR = 91,
  BANK_ID_API = 92,
  ALREADY_ACTIVE_APPLICATION = 100,
  USER_OWES_INTEREST = 109,
  USER_OWES_PRINCIPAL_AND_INTEREST = 110,
  USERS_LAST_PAYBACK_IS_STILL_PROCESSING = 111
}

/** @deprecated use numbers instead */
export enum ResponseStatus {
  CODE_400 = 400,
  CODE_401 = 401,
  CODE_403 = 403,
  CODE_404 = 404,
  CODE_409 = 409,
  CODE_412 = 412
}
