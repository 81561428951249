import { QueryParam } from '../constants';
import { routeMap } from '../routes/routeMap';

/**
 * Generates a url which may contain query params
 *
 * @param route relative path
 * @param options
 *   - queryParams: Optional params to append to the url as query params
 *   - base: is the url upon which the route will be created on.
 *           If none is provided then window.location.origin is used
 *   - relative: if true, the url will be relative to the current location
 */
export const constructURL = (
  route: string,
  {
    queryParams = {},
    base = window.location.origin,
    relative = false
  }: {
    queryParams?: Record<string, string>;
    base?: string;
    relative?: boolean;
  } = {}
) => {
  const url = new URL(route, base);

  for (const [key, value] of Object.entries(queryParams)) {
    if (value !== undefined) {
      url.searchParams.set(key, value);
    }
  }

  return relative ? `${url.pathname}${url.search}` : `${url.toString()}`;
};

export const constructSelectAccountURL = (url: string) =>
  `${routeMap.selectAccount}?${new URLSearchParams({
    [QueryParam.SELECT_ACCOUNT_REDIRECT]: url
  }).toString()}`;

export const getBankIdAppUrl = (autoStartToken: string, redirect = 'null') =>
  constructURL('bankid:///', {
    queryParams: {
      autostarttoken: autoStartToken,
      redirect
    }
  });
export const getRelativePathName = (path: string, depth = 1) => {
  const pathSegments = path.split('/').filter(Boolean);

  if (pathSegments.length < depth) {
    throw new Error(`Path ${path} does not have enough segments to get the relative path name`);
  }

  return `/${pathSegments.slice(-depth).join('/')}`;
};

export const getQueryParams = (search = window.location.search) => {
  const searchParams = new URLSearchParams(search);
  return Object.fromEntries(searchParams.entries());
};
