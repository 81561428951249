import { AnimationItem } from 'lottie-web/build/player/lottie_light';
import lottie from 'lottie-web/build/player/lottie_light';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import bouncyCircleLoader from '../../assets/animations/bouncyCircleLoader.json';
import timeHourglass from '../../assets/animations/timeHourglass.json';

const Wrapper = styled.div<Pick<Props, 'width' | 'height'>>`
  display: inline-flex;
  width: ${({ width }) => width ?? undefined};
  height: ${({ height }) => height ?? undefined};
`;

const ANIMATION_DATA_MAP = {
  bouncyCircleLoader,
  timeHourglass
};

type Props = {
  animation: keyof typeof ANIMATION_DATA_MAP;
  width?: string;
  height?: string;
  className?: string;
};

const Animation = ({ animation, width, height, className }: Props) => {
  const elementRef = useRef(null);

  useEffect(() => {
    let animationItem: AnimationItem;
    if (elementRef.current !== null) {
      animationItem = lottie.loadAnimation({
        container: elementRef.current,
        animationData: ANIMATION_DATA_MAP[animation]
      });
    }

    return function cleanup() {
      animationItem?.destroy();
    };
  }, [animation]);

  return (
    <Wrapper
      data-testid="animation-wrapper"
      className={className}
      width={width}
      height={height}
      ref={elementRef}
    />
  );
};

export default Animation;
