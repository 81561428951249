import axiosInstance from '../../utils/axiosInstance';
import {
  ApplicantAddressesPayload,
  ApplicantIncomePayload,
  ApplicantOutgoingsPayload,
  ApplicantPersonalDetailsPayloadUK,
  ApplicantResponseUK
} from './types';

export const createApplicant = async (payload: ApplicantPersonalDetailsPayloadUK) => {
  const response = await axiosInstance.post<ApplicantResponseUK>('/uk/applicants', payload);
  return response.data;
};

export const updateApplicant = async (applicantId: string, payload: ApplicantPersonalDetailsPayloadUK) => {
  const response = await axiosInstance.put<ApplicantResponseUK>(`/uk/applicants/${applicantId}`, payload);
  return response.data;
};

export const getApplicant = async (applicantId: string) => {
  const response = await axiosInstance.get<ApplicantResponseUK>(`/uk/applicants/${applicantId}`);
  return response.data;
};

export const updateApplicantAddresses = async (applicantId: string, payload: ApplicantAddressesPayload) => {
  const response = await axiosInstance.put<ApplicantResponseUK>(
    `/uk/applicants/${applicantId}/addresses`,
    payload
  );
  return response.data;
};

export const updateApplicantIncome = async (applicantId: string, payload: ApplicantIncomePayload) => {
  const response = await axiosInstance.put<ApplicantResponseUK>(
    `/uk/applicants/${applicantId}/income`,
    payload
  );
  return response.data;
};

export const updateApplicantOutgoings = async (applicantId: string, payload: ApplicantOutgoingsPayload) => {
  const response = await axiosInstance.put<ApplicantResponseUK>(
    `/uk/applicants/${applicantId}/outgoings`,
    payload
  );
  return response.data;
};
