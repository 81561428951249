import { LocalStorageKeys } from '../constants/LocalStorage';
import { environment } from '../utils/env.utils';

const loadDevTools = (callback: () => void) => {
  const isDisabled = localStorage.getItem(LocalStorageKeys.DEVTOOLS) === 'false';
  const isDev = ['develop', 'local'].includes(environment.env);

  if (isDev && !isDisabled) {
    void import('./devtools').then(devTools => devTools.install()).finally(callback);
  } else {
    callback();
  }
};

export default loadDevTools;
