import { Accordion, Stack } from '@fairlo/ui-kit';
import { ComponentProps } from 'react';

type Props = ComponentProps<typeof Accordion.Item> & {
  spacing?: ComponentProps<typeof Stack>['spacing'];
};

const AccordionStackItem = ({ spacing, children, ...restProps }: Props) => (
  <Accordion.Item {...restProps}>
    <Stack spacing={spacing}>{children}</Stack>
  </Accordion.Item>
);

export default AccordionStackItem;
