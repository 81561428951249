import { Button, List, ListItem, ListItemText, Stack } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { FullWidthContainer } from '../../styled';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

type Payload = { depositAmount: string; monthlyAmount: string };
type Props = ModalProps<Payload>;

function DepositSuccess({ closeModal, confirmAction, payload: { depositAmount, monthlyAmount } }: Props) {
  const { t } = useTranslation(['Modal', 'Common']);

  return (
    <Modal title={t('DepositSuccess.title')}>
      <Stack>
        <ModalParagraph>{t('DepositSuccess.info')}</ModalParagraph>
        <FullWidthContainer>
          <List>
            <ListItem>
              <ListItemText text={t('DepositSuccess.depositAmount')} />
              <ListItemText text={t('Common:moneyWithTrailingZeros', { amount: depositAmount })} />
            </ListItem>
            <ListItem>
              <ListItemText text={t('DepositSuccess.monthlyAmount')} />
              <ListItemText text={t('Common:moneyWithTrailingZeros', { amount: monthlyAmount })} />
            </ListItem>
          </List>
        </FullWidthContainer>
      </Stack>
      <Modal.Actions>
        <Button onClick={confirmAction ?? closeModal}>{t('DepositSuccess.button')}</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DepositSuccess;
