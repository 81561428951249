import { AnchorButton, Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import marketingSiteRouteMap from '../../../constants/marketingSiteRouteMap';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

const BankAccountUpdateFail = ({ confirmAction }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button', 'Common']);

  return (
    <Modal title={t('BankAccountUpdateFail.title')}>
      <ModalParagraph>{t('BankAccountUpdateFail.info')}</ModalParagraph>
      <Modal.Actions>
        <AnchorButton color="neutral" variant="outlined" href={marketingSiteRouteMap.contact}>
          {t('Common:contactUs')}
        </AnchorButton>
        <Button onClick={confirmAction}>{t('Button:tryAgain')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BankAccountUpdateFail;
