import { CreditFeaturesResponse, DebtConsolidationFeaturesResponse } from '../../../../api';

export const selectMonthlyPaymentData = (
  features: CreditFeaturesResponse | DebtConsolidationFeaturesResponse
) => ({
  sliderAmounts: features.monthlyPayment.map(item => item.stepAmount),
  features: features.monthlyPayment
});

export const selectWithdrawData = (features: CreditFeaturesResponse) => ({
  sliderAmounts: features.withdraw.map(item => item.stepAmount),
  features: features.withdraw
});

export const selectDepositData = (features: CreditFeaturesResponse | DebtConsolidationFeaturesResponse) => ({
  sliderAmounts: features.deposit.map(item => item.stepAmount),
  features: features.deposit
});
