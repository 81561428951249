import { TrackEventProperties } from '../../analytics';
import { flattenObject } from '../../utils/flattenObject';

export const filterTrackEventProperties = (props: Record<string, unknown>) => {
  const filteredProps: TrackEventProperties = {};

  Object.entries(props).forEach(([key, value]) => {
    if (key === 'revenue' && typeof value !== 'number') {
      return;
    } else if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      typeof value === 'boolean' ||
      (Array.isArray(value) && value.every(item => typeof item === 'string')) ||
      (Array.isArray(value) && value.every(item => typeof item === 'number')) ||
      (Array.isArray(value) && value.every(item => typeof item === 'boolean')) ||
      value === undefined
    ) {
      filteredProps[key] = value;
    }
  });

  return filteredProps;
};

export const transformTrackEventProperties = (params: Record<string, unknown>): TrackEventProperties =>
  filterTrackEventProperties(flattenObject(params));
