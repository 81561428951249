import { useEffect, useRef } from 'react';
import { untrackUser } from '../../../../analytics';
import { LocalStorageKeys } from '../../../../constants/LocalStorage';
import useIdentifyUser from '../../../../hooks/useIdentifyUser';
import { useAuthToken } from '../../../../store/authTokenStore';
import { parseJWT } from '../../../../utils/value';

const useHandleUserTracking = () => {
  const lastIdentifiedAuthToken = useRef<string | null>(null);
  const authToken = useAuthToken();
  const identifyUser = useIdentifyUser();

  useEffect(
    function track() {
      if (!authToken || lastIdentifiedAuthToken.current === authToken) {
        return;
      }

      lastIdentifiedAuthToken.current = authToken;
      const jwt = parseJWT(authToken);
      if (!jwt?.user_id) {
        return;
      }

      /**
       * To avoid increasing our Monthly Active Users (MAU) count in DevCycle, we store the user
       * id in local storage and initialize DevCycle with it.
       *
       * For the same reason we do not reset DevCycle's state when the user logs out.
       */
      localStorage.setItem(LocalStorageKeys.USER_ID, jwt.user_id);
      void identifyUser(jwt.user_id);
    },
    [authToken, identifyUser]
  );

  useEffect(
    function untrack() {
      if (!!authToken || lastIdentifiedAuthToken.current === null) {
        return;
      }

      lastIdentifiedAuthToken.current = null;
      /**
       * We purposefully don't call DevCycle's reset function here because it would count it
       * as a separate Monthly Active User (MAU), which is not accurate.
       */
      void untrackUser();
    },
    [authToken]
  );
};

export default useHandleUserTracking;
