import { useMutation } from '@tanstack/react-query';

import API, { SEPayOverduePayload, UKPayOverduePayload } from '../../../../../api';
import { useOpenPaymentProvider } from '../../../../../components/PaymentProvider/PaymentProviderProvider';
import { CustomUseMutationOptions } from '../../../../../types/reactQuery';

export const usePayOverdueMutation = <PayOverduePayload extends SEPayOverduePayload | UKPayOverduePayload>(
  options?: CustomUseMutationOptions<string, PayOverduePayload>
) => {
  const openPaymentProvider = useOpenPaymentProvider();

  return useMutation({
    mutationFn: payload => API.paymentProvider.payOverdue(payload),
    onSuccess: openPaymentProvider,
    ...options
  });
};
