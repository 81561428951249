import { useTranslation } from 'react-i18next';

import { routeMap } from '../../../routes/routeMap';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { AnchorButton } from '@fairlo/ui-kit';
import { Link } from 'react-router-dom';

const NoConnectedBankAccountInfo = () => {
  const { t } = useTranslation(['Modal', 'CreditGranted']);

  return (
    <Modal title={t('NoConnectedBankAccountInfo.title')}>
      <ModalParagraph>{t('NoConnectedBankAccountInfo.info')}</ModalParagraph>
      <Modal.Actions>
        <AnchorButton as={Link} to={routeMap.bankAccount.root} color="primary">
          {t('CreditGranted:startTransmission')}
        </AnchorButton>
      </Modal.Actions>
    </Modal>
  );
};

export default NoConnectedBankAccountInfo;
