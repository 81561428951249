import Common from './components';
import SE from './components/SE';
import UK from './components/UK';

const modalMap = {
  ...Common,
  SE,
  UK
} as const;

export type CommonModalTypes = keyof typeof Common;
export type SEModalTypes = keyof typeof SE;
export type UKModalTypes = keyof typeof UK;
export type ModalTypes = CommonModalTypes | `UK.${UKModalTypes}` | `SE.${SEModalTypes}`;

export default modalMap;
