type JWT = {
  user_id: string;
  user_email: string;
  nationalNumber: string;
  country: string;
  email: string;
  admin: boolean;
  iat: number;
  exp: number;
};

export const parseJWT = (str: string): JWT | null => {
  try {
    return JSON.parse(atob(str.split('.')[1]));
  } catch {
    return null;
  }
};
