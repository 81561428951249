import { Heading, Stack } from '@fairlo/ui-kit';

import AccordionStackItem from '../../../AccordionStackItem';
import Modal from '../../Modal';
import { ModalListItem, ModalParagraph } from '../shared';
import { ModalAccordion, Table } from '../styled';

const PrivacyNotice = () => (
  <Modal title="Fairlo UK Limited Privacy Notice">
    <Stack spacing={24}>
      <Heading as="h2" size="xs">
        Fairlo’s approach to personal data
      </Heading>

      <ModalParagraph>Last Updated: 13 September 2023</ModalParagraph>

      <ModalParagraph>
        Fairlo takes protecting your privacy very seriously, and for us it is important that you feel safe
        when contacting us, taking credit through us or otherwise letting us process your personal data.
      </ModalParagraph>

      <div>
        <ModalParagraph>In this policy, we describe:</ModalParagraph>

        <ul>
          <ModalListItem>
            Why we need to process your personal data when you contact us, apply for credit or enter into a
            credit agreement with us
          </ModalListItem>
          <ModalListItem>How we process your personal data</ModalListItem>
          <ModalListItem>What rights you have</ModalListItem>
          <ModalListItem>How you can get in touch with us if you have any questions</ModalListItem>
        </ul>
      </div>

      <ModalAccordion>
        <AccordionStackItem label={{ value: '1. Who we are', as: 'h2' }} spacing={24}>
          <ModalParagraph>
            We are Fairlo UK Limited with company number 13566128 and address Wisteria Grange Barn, Pikes End,
            Pinner, Middlesex, United Kingdom, HA5 2EX and is registered with the Information Commissioner’s
            Office (Registration No. ZB258571). We are responsible for the personal data Fairlo processes
            about you.
          </ModalParagraph>

          <ModalParagraph>
            You can contact us at any time at <a href="mailto:privacy@fairlo.co.uk">privacy@fairlo.co.uk</a>{' '}
            if you want to know more about how we process your personal data. You can also read more about us
            on our website <a href="https://fairlo.co.uk">www.fairlo.co.uk.</a>
          </ModalParagraph>
        </AccordionStackItem>

        <AccordionStackItem label={{ value: '2. Why we need your personal data', as: 'h2' }} spacing={24}>
          <ModalParagraph>
            Personal data means any information which relates to an individual and can be used for the
            purposes of identification, either directly or indirectly, typically through the use of an
            identifier.
          </ModalParagraph>

          <ModalParagraph>
            Our starting point is to only process the personal data required for us to be able to assess your
            credit application and fulfill our obligations under the credit agreement. We also need to process
            your personal data in order to meet legal requirements, such as complying with the Financial
            Conduct Authority&apos;s and ICO’s regulations.
          </ModalParagraph>

          <ModalParagraph>
            We also process your personal data for marketing, customer analysis, product development, and to
            make your customer experience as good as possible.
          </ModalParagraph>
        </AccordionStackItem>

        <AccordionStackItem
          label={{ value: '3. What and how do we collect your personal data', as: 'h2' }}
          spacing={24}
        >
          <div>
            <ModalParagraph>
              We collect, process and use the following personal data when you contact us, apply for credit,
              enter into an agreement with us and as part of our ongoing relationship.
            </ModalParagraph>
            <ul>
              <ModalListItem>
                <strong>Identity -</strong> first name, last name, date of birth.
              </ModalListItem>
              <ModalListItem>
                <strong>Contact details -</strong> address, email, mobile number.
              </ModalListItem>
              <ModalListItem>
                <strong>Financial information -</strong> your income and outgoings including the number of
                dependents, employment status, preferred repayment date and credit history.
              </ModalListItem>
              <ModalListItem>
                <strong>Banking details -</strong> your bank account number and sort code.
              </ModalListItem>
              <ModalListItem>
                <strong>Usage information -</strong> about how you have used the website, your Fairlo account
                and communications with Fairlo. This includes things like date and time, time on a page,
                navigation between pages, page interaction and other similar information.
              </ModalListItem>
              <ModalListItem>
                <strong>Device information -</strong> about what device you have used to access the website,
                your Fairlo online account and communications with Fairlo. This includes things like device
                type, operating system, browser type, the Internet Protocol (IP) address.
              </ModalListItem>
              <ModalListItem>
                <strong>Vehicle information -</strong> about the car make, model, value, current lender,
                settlement amount, cost of running.
              </ModalListItem>
            </ul>
          </div>

          <div>
            <ModalParagraph>These are collected from a variety of sources:</ModalParagraph>
            <ul>
              <ModalListItem>Information you give us</ModalListItem>
              <ModalListItem>
                Information we receive from third parties, including third parties who provide services to us
                (for example credit reference agencies or fraud prevention agencies)
              </ModalListItem>
              <ModalListItem>Information shared by credit intermediaries</ModalListItem>
              <ModalListItem>
                Information we learn about you through our relationship with you and the way you operate your
                products
              </ModalListItem>
              <ModalListItem>
                Information that we gather from the technology which you use to access our services
              </ModalListItem>
              <ModalListItem>Information gathered through website cookies</ModalListItem>
            </ul>
          </div>

          <ModalParagraph>
            More information about what information is shared with credit reference agencies can be found in
            section &quot;Sharing your personal data with third parties&quot;.
          </ModalParagraph>
        </AccordionStackItem>

        <AccordionStackItem label={{ value: '4. How we process your personal data', as: 'h2' }} spacing={24}>
          <ModalParagraph>
            Data protection law says that we can only use personal data if we have a proper reason to do so.
            For example, these reasons include fulfilling a contract we have with you, when we have a legal
            duty, when it is in our legitimate interest or when you consent to its use. When data protection
            law allows us to process your personal data for our own legitimate interests, it is only allowed
            provided those interests do not override your own interests and/ or your fundamental rights and
            freedoms.
          </ModalParagraph>

          <ModalParagraph>
            An example of where we would process your personal data for our legitimate interests would be
            where you believe you are the victim of fraud and in order to investigate your claim we may have
            to share your name and account number, payment and other details of the case with any other bank
            involved. Sharing personal data in these circumstances would not only be in our legitimate
            interest but also yours. An example of us using your personal data when we have a legal duty, is
            where we must do so in order to comply with anti-money laundering obligations.
          </ModalParagraph>

          <ModalParagraph>
            Our purposes for processing your personal data are grouped under our four legal bases for
            processing below.
          </ModalParagraph>

          <div>
            <ModalParagraph as="h3">
              <strong>A contractual obligation basis</strong>
            </ModalParagraph>
            <ul>
              <ModalListItem>
                To consider and process applications made for products and services we provide, which may
                involve sharing information with credit reference agencies and fraud prevention agencies, or
                by other means, such as assessing information we hold about you, for instance, where we have
                an existing relationship with you.
              </ModalListItem>

              <ModalListItem>
                To deliver the products and services we provide, including:
                <ul>
                  <ModalListItem>Transferring withdrawals to your nominated account</ModalListItem>
                  <ModalListItem>Receiving repayments from your nominated account</ModalListItem>
                  <ModalListItem>
                    Providing you with information and guidance on the products and services you hold
                  </ModalListItem>
                  <ModalListItem>
                    Providing statements, reminders and notices informing you of forthcoming changes, such as
                    increasing or decreasing credit limits
                  </ModalListItem>
                  <ModalListItem>Calculating the interest which may apply to your account</ModalListItem>
                  <ModalListItem>
                    To address enquiries or complaints we may receive from you or a representative appointed
                    by you
                  </ModalListItem>
                  <ModalListItem>
                    Assisting you with managing the products and services you hold
                  </ModalListItem>
                </ul>
              </ModalListItem>
            </ul>
          </div>

          <div>
            <ModalParagraph as="h3">
              <strong>A legal obligation basis</strong>
            </ModalParagraph>
            <ul>
              <ModalListItem>Confirm your identity</ModalListItem>
              <ModalListItem>Perform checks to detect and prevent financial crime</ModalListItem>
              <ModalListItem>
                Share data with credit reference agencies and fraud prevention agencies
              </ModalListItem>
              <ModalListItem>Comply with legal and regulatory requirements</ModalListItem>
              <ModalListItem>Where we have a duty to protect vulnerable customers</ModalListItem>
            </ul>
          </div>

          <div>
            <ModalParagraph as="h3">
              <strong>A legitimate interest basis</strong>
            </ModalParagraph>
            <ul>
              <ModalListItem>
                Understanding how our customers use our products and services, so we can improve these
              </ModalListItem>
              <ModalListItem>
                Developing new products and services and identifying which may be of interest to you
              </ModalListItem>
              <ModalListItem>
                Sharing information with organisations who introduce you to us under a commercial agreement –
                for instance, where we pay them commission
              </ModalListItem>
              <ModalListItem>
                Improving our systems and processes, which may include using your personal data to test the
                accuracy of these, but only where it is essential to do so
              </ModalListItem>
              <ModalListItem>To recover money owed to us</ModalListItem>
              <ModalListItem>
                To invite you to participate in market research and customer surveys;
              </ModalListItem>
              <ModalListItem>To sell to, or purchase debts from other firms</ModalListItem>
              <ModalListItem>
                Sharing your personal data with any person to whom we may transfer, or may consider
                transferring any of our rights or business
              </ModalListItem>
              <ModalListItem>
                To share information with third parties for the purpose of preventing fraud and financial
                crime
              </ModalListItem>
              <ModalListItem>Identifying and managing risks to our business</ModalListItem>
            </ul>
          </div>

          <ModalParagraph as="h3">
            <strong>A consent basis</strong>
          </ModalParagraph>
          <ModalParagraph>
            Where we have the relevant permissions: contacting you to make you aware of these products and
            services, including sharing information with third parties who may add to this information they
            know about you and use this to deliver such messages to you on our behalf via social media – note:
            we may contact you for a reasonable period after you cease your relationship with us.
          </ModalParagraph>

          <ModalParagraph as="h3">
            <strong>Marketing</strong>
          </ModalParagraph>
          <ModalParagraph>
            We may use your personal data to tell you about relevant products and offers that we think you may
            find interesting. We can only use your personal data to send you marketing messages if you have
            given your consent or it is for a legitimate interest (when we have a business or commercial
            reason to use your information).
          </ModalParagraph>
          <ModalParagraph>
            You can ask us to stop sending you marketing messages by contacting us at any time, although you
            will still receive important information, and communications of any changes, to your existing
            products and services.
          </ModalParagraph>
          <ModalParagraph>
            You can change your mind and update your choices at any time by using the “unsubscribe” or “opt
            out” option in any marketing communication you receive from us or by contacting us at{' '}
            <a href="mailto:privacy@fairlo.co.uk">privacy@fairlo.co.uk.</a>
          </ModalParagraph>
        </AccordionStackItem>

        <AccordionStackItem
          label={{ value: '5. How long we keep your personal data', as: 'h2' }}
          spacing={24}
        >
          <ModalParagraph>
            We will retain your personal data for as long as we are obliged, under relevant legislation and
            regulation, or where no such rules apply, for no longer than it is necessary for our lawful
            purposes. We will keep your personal data as long as you are our customer, even though we will
            anonymise or erase personal data we no longer need on an ongoing basis. After you are no longer a
            customer with us, we will usually not keep your personal data for more than seven years from the
            point at which the obligation to retain a record containing your personal data begins. The
            retention period of your personal data may need to be extended where we require this to bring or
            defend legal claims. We may also retain data for longer periods for statistical purposes, and if
            so we will anonymise your personally identifiable data.
          </ModalParagraph>
          <ModalParagraph>
            If you have given your consent to marketing, we will process your personal data for that purpose
            as long as we have your consent to it. You can at any time request that we stop processing your
            personal data for this purpose.
          </ModalParagraph>
        </AccordionStackItem>

        <AccordionStackItem label={{ value: '6. How we protect your personal data', as: 'h2' }} spacing={24}>
          <ModalParagraph>
            Your safety is important to us. We have therefore taken appropriate technical, organizational and
            administrative security measures to protect your personal data from unauthorized access and other
            unauthorized processing. Our IT systems are compatible with the requirements of the GDPR and other
            applicable data protection legislation.
          </ModalParagraph>
        </AccordionStackItem>

        <AccordionStackItem
          label={{ value: '7. Sharing your personal data with third parties', as: 'h2' }}
          spacing={24}
        >
          <ModalParagraph>
            We do not disclose your information to other companies unless required by law or required for you
            to apply for credit or enter into a credit agreement with us. We might also disclose your
            information when we have a legitimate interest to do so.
          </ModalParagraph>
          <ModalParagraph>
            If you apply for credit, we need to disclose your personal information to our partners who perform
            the credit assessment and credit assessment required for you to be granted credit by us. We also
            need to provide your personal information so that the credit you have been granted can be
            transferred to your bank account.
          </ModalParagraph>
          <ModalParagraph>
            When required by law or other regulations, we may need to disclose your information to authorities
            and other organizations. We may also need to disclose your information if it is necessary to
            exercise, establish or monitor our legal claims.
          </ModalParagraph>
          <ModalParagraph>
            We do not disclose your personal information to other companies or organizations for their
            marketing purposes, unless we have obtained your consent to do so.
          </ModalParagraph>

          <ModalParagraph as="h3">
            <strong>Credit Reference Agencies</strong>
          </ModalParagraph>
          <ModalParagraph>
            In order to process your application we will supply your personal information to credit reference
            agencies (CRAs) and they will give us information about you, such as about your financial history.
            We do this to assess creditworthiness and product suitability, check your identity, manage your
            account, trace and recover debts and prevent criminal activity.
          </ModalParagraph>
          <ModalParagraph>
            We will also continue to exchange information about you with CRAs on an ongoing basis, including
            about your settled accounts and any debts not fully repaid on time. CRAs will share your
            information with other organisations. Your data will also be linked to the data of your spouse,
            any joint applicants or other financial associates.
          </ModalParagraph>
          <ModalParagraph>
            The identities of the CRAs, and the ways in which they use and share Personal Data, are explained
            in more detail at{' '}
            <a href="https://www.experian.co.uk/legal/crain/">https://www.experian.co.uk/legal/crain/</a> and{' '}
            <a href="https://www.transunion.co.uk/legal/privacy-centre?#pc-credit-reference">
              https://www.transunion.co.uk/legal/privacy-centre?#pc-credit-reference.
            </a>
          </ModalParagraph>

          <div>
            <ModalParagraph as="h3">
              <strong>Types of third parties we may share data with</strong>
            </ModalParagraph>
            <ul>
              <ModalListItem>
                <strong>Communications providers</strong> e.g. mail, email and SMS services - to allow us to
                contact you about your Fairlo account, or send marketing communications where appropriate.
              </ModalListItem>
              <ModalListItem>
                <strong>Credit reference agencies -</strong> to access and update your credit history to
                ensure we are lending responsibly on an ongoing basis, and to support identity and income
                verification.
              </ModalListItem>
              <ModalListItem>
                <strong>Fraud prevention services -</strong> to access and share information to help reduce
                the risk of fraud.
              </ModalListItem>
              <ModalListItem>
                <strong>Debt collection agencies -</strong> to support in our support activities if you were
                to fall behind on repayments.
              </ModalListItem>
              <ModalListItem>
                <strong>Payment processors -</strong> to facilitate withdrawals, additional repayments and
                your regular monthly repayments.
              </ModalListItem>
              <ModalListItem>
                <strong>Software providers -</strong> to allow us to run the product.
              </ModalListItem>
              <ModalListItem>
                <strong>Web analytics service providers -</strong> to allow us to optimise our website and
                customer journeys, and monitor performance.
              </ModalListItem>
              <ModalListItem>
                <strong>Hosting service providers -</strong> for storing our databases and code base to
                provide our products and services.
              </ModalListItem>
              <ModalListItem>
                <strong>Credit intermediaries -</strong> where Fairlo account information is accessed through
                their services, to complete the population of the introducers account (if incomplete), or to
                support monitoring and reporting.
              </ModalListItem>
            </ul>
          </div>
        </AccordionStackItem>

        <AccordionStackItem label={{ value: '8. Your rights', as: 'h2' }} spacing={24}>
          <ModalParagraph>
            When we process personal data about you, you have several rights under the data protection
            legislation. You have the right to contact us at any time regarding these, and if you want to
            exercise any of the rights described below, you can reach us at{' '}
            <a href="mailto:privacy@fairlo.co.uk">privacy@fairlo.co.uk.</a>
          </ModalParagraph>
          <ModalParagraph>
            We reserve the right to take appropriate protective and safety measures in order to ensure that
            you are who you claim to be when you contact us. If you are unable to prove your identity in a
            credible manner, we may not be able to accommodate your request.{' '}
          </ModalParagraph>
          <ModalParagraph as="h3">
            <strong>The right to be informed</strong>
          </ModalParagraph>
          <ModalParagraph>
            You have the right to be provided with clear, transparent and easily understandable information
            about how we use your personal data and your rights. We fulfil this right by giving you this
            notice.
          </ModalParagraph>
          <ModalParagraph as="h3">
            <strong>The right of access</strong>
          </ModalParagraph>
          <ModalParagraph>
            You have the right to know which personal data we process about you. If you want to know, you can
            get a compiled register extract from us that contains the personal data we process about you.
          </ModalParagraph>
          <ModalParagraph as="h3">
            <strong>The right to to rectification</strong>
          </ModalParagraph>
          <ModalParagraph>
            You can ask us to change or complete any inaccurate or incomplete personal data held about you.
          </ModalParagraph>
          <ModalParagraph as="h3">
            <strong>The right to erasure</strong>
          </ModalParagraph>
          <ModalParagraph>
            This is also known as “the right to be forgotten” and this means that you can ask us to delete
            your personal data where it is no longer necessary for us to use it, you have withdrawn consent
            (where applicable), or where we have no lawful basis for keeping it or otherwise using it. There
            are limited exceptions, for example where we need to use the information to bring or defend a
            legal claim.
          </ModalParagraph>
          <ModalParagraph as="h3">
            <strong>The right to data portability</strong>
          </ModalParagraph>
          <ModalParagraph>
            In certain circumstances, you have the right to receive the information we process about you in a
            general, written, machine-readable and structured format. You have the right to do so with regard
            to the personal data that you have provided to us and that we process with the support of your
            consent or when the personal data is required to enter into or fulfill an agreement with you.
          </ModalParagraph>
          <ModalParagraph as="h3">
            <strong>The right to restrict processing</strong>
          </ModalParagraph>
          <ModalParagraph>
            Under certain conditions, you have the right to request that we limit our processing of your data.
            This means that we mark the data so that in the future we only process them for certain specific
            purposes. We may not offer you credit if we restrict the processing of your personal data.
          </ModalParagraph>
          <ModalParagraph as="h3">
            <strong>The right to object</strong>
          </ModalParagraph>
          <ModalParagraph>
            You have the right to object to the processing of personal data that is performed for the purpose
            of performing a task of general interest, as part of the exercise of authority or in our
            legitimate interest. We process some of your personal data on the basis of our legitimate
            interest, and you have the right to object to such processing.
          </ModalParagraph>
          <ModalParagraph as="h3">
            <strong>Rights in relation to automated decision making and profiling</strong>
          </ModalParagraph>
          <ModalParagraph>
            We use automated decisioning when we assess whether your application for credit can be granted,
            assessing your account behaviour and determining the appropriate action to take, where your
            account has gone into arrears or default.
          </ModalParagraph>
          <ModalParagraph>
            This means that our decision is made automatically without any human intervention. We do this to
            make decisions more consistent, fair and quicker. You have the right not to be subject to a
            decision based on solely automated processing, including profiling, if this will have a legal or
            other significant effect on you (unless certain exceptions apply).
          </ModalParagraph>
          <ModalParagraph>
            We will use your personal data in profiling in order to identify your suitability for products and
            services we offer and to inform you of these. Where an individual’s financial history suggests
            they are unlikely to be accepted for a particular product or service, we may use that data to opt
            them out of receiving information about that product or service. This is in individuals’ interests
            as it helps stop them receiving irrelevant marketing or being targeted with marketing about
            products that they cannot afford. These types of profiling are only used in relation to our own
            products and services and as such are generally not considered to have a significant effect on
            your rights and interests.
          </ModalParagraph>
          <ModalParagraph as="h3">
            <strong>Right to lodge a complaint</strong>
          </ModalParagraph>
          <ModalParagraph>
            You also have the right to complain to the Information Commissioner’s Office. You can call their
            helpline on 0303 123 1113, or you can visit their website (
            <a href="https://ico.org.uk/">www.ico.org.uk</a>) for information on{' '}
            <a href="https://ico.org.uk/make-a-complaint">how to make a data protection complaint.</a>
          </ModalParagraph>
        </AccordionStackItem>
      </ModalAccordion>

      <Heading as="h2" size="xs">
        Cookies
      </Heading>

      <ModalParagraph>Fairlo&apos;s approach to cookies</ModalParagraph>

      <ModalParagraph>
        To make our site work properly, we sometimes send small files to your computer. These files are called
        cookies. Most websites do the same thing to improve your experience.
      </ModalParagraph>
      <ModalAccordion>
        <AccordionStackItem label={{ value: '1. What are cookies?', as: 'h2' }} spacing={24}>
          <ModalParagraph>
            Cookies are small text files that are saved on your computer, phone or tablet when you visit
            websites. The cookies help the website remember your settings (e.g. username, language, text size
            and other preferences) for a certain period of time. The idea is that you should not have to redo
            the settings every time you enter the website or browse between different pages. The cookies can
            also be used to save information about how you use the site, which can help customise your site
            experience.
          </ModalParagraph>
        </AccordionStackItem>
        <AccordionStackItem label={{ value: '2. How we use cookies', as: 'h2' }} spacing={24}>
          <ModalParagraph>Fairlo uses cookies from third parties. These are mainly used to:</ModalParagraph>
          <ul>
            <ModalListItem>
              Analyze website behavior to improve the website and its user experience
            </ModalListItem>
            <ModalListItem>Build segments for advertising</ModalListItem>
            <ModalListItem>Show relevant ads to previous visitors</ModalListItem>
            <ModalListItem>Minimize advertising to visitors where it is not relevant</ModalListItem>
            <ModalListItem>Test different content on the website</ModalListItem>
          </ul>
        </AccordionStackItem>
        <AccordionStackItem label={{ value: '3. The cookies we use', as: 'h2' }} spacing={24}>
          <ModalParagraph>A list of the cookies present on the website:</ModalParagraph>
          <div style={{ overflowX: 'auto' }}>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Purpose</th>
                  <th>Retention period</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ajs_user_id</td>
                  <td>Analytics</td>
                  <td>
                    This cookie is set by Segment to help track visitor usage, events, target marketing, and
                    also measure application performance and stability.
                  </td>
                  <td>Never</td>
                </tr>
                <tr>
                  <td>ajs_anonymous_id</td>
                  <td>Analytics</td>
                  <td>
                    This cookie is set by Segment to count the number of people who visit a certain site by
                    tracking if they have visited before.
                  </td>
                  <td>1 year</td>
                </tr>
                <tr>
                  <td>_fw_crm_v</td>
                  <td>Analytics</td>
                  <td>FreshChat uses this cookie to track visitor identity and chat sessions performed.</td>
                  <td>1 year</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </AccordionStackItem>
        <AccordionStackItem label={{ value: '4. How you can control the cookies', as: 'h2' }} spacing={24}>
          <ModalParagraph>
            You can control and delete cookies exactly as you want, whenever you want. You can even delete all
            cookies on your computer and you can set your browser so that it does not receive or send any
            cookies. However if you did that, you may need to reset certain settings each time you access a
            website and some services and features may not work. Read more about how to handle cookies in
            general at{' '}
            <a
              href="https://allaboutcookies.org/how-to-manage-cookies"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://allaboutcookies.org/how-to-manage-cookies
            </a>
          </ModalParagraph>
        </AccordionStackItem>
      </ModalAccordion>
    </Stack>
  </Modal>
);

export default PrivacyNotice;
