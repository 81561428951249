import { useQuery } from '@tanstack/react-query';
import API, { CreditActionsResponse } from '../../../../api';
import { queryKeys } from '../../../../api/utils/queryKeys';
import { CustomUseQueryOptions } from '../../../../types/reactQuery';

const useCreditActionsQuery = <TData = CreditActionsResponse>(
  options?: CustomUseQueryOptions<CreditActionsResponse, TData>
) =>
  useQuery({
    queryKey: queryKeys.creditActions,
    queryFn: API.credit.getCreditActions,
    ...options
  });

export default useCreditActionsQuery;
