import axiosInstance from '../utils/axiosInstance';
import { AuthenticatePayload, AuthenticateResponse, LoginResponse } from './types';

export const login = async (email: string) => {
  const response = await axiosInstance.post<LoginResponse>('/login', {
    email
  });
  return response.data;
};

export const authenticate = async (payload: AuthenticatePayload) => {
  const response = await axiosInstance.post<AuthenticateResponse>('/login/authenticate', payload);
  return response.data;
};
