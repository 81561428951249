import { Button, Stack } from '@fairlo/ui-kit';
import styled from 'styled-components';

import directDebitImg from '../../../../assets/images/direct-debit.svg';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

const DirectDebitImg = styled.img`
  align-self: flex-start;
  width: 52px;
`;

type Payload = { completed?: boolean };
type Props = ModalProps<Payload>;

const DirectDebitTerms = ({ closeModal, payload: { completed = false } = {} }: Props) => (
  <Modal title={completed ? 'Direct Debit set up completed' : 'Direct Debit Guarantee'}>
    <Stack>
      <DirectDebitImg src={directDebitImg} alt="Direct Debit" />
      <ModalParagraph>
        The Guarantee is offered by all banks and building societies that accept instructions to pay Direct
        Debits.
      </ModalParagraph>

      <ModalParagraph>
        If there are any changes to the amount, date or frequency of your Direct Debit we will notify you
        (normally 10 working days) in advance of your account being debited or as otherwise agreed. If you
        request Fairlo to collect a payment, confirmation of the amount and date will be given to you at the
        time of the request.
      </ModalParagraph>

      <ModalParagraph>
        If an error is made in the payment of your Direct Debit, by Fairlo or your bank or building society,
        you are entitled to a full and immediate refund of the amount paid from your bank or building society.
      </ModalParagraph>

      <ModalParagraph>
        If you receive a refund you are not entitled to you must pay it back when we ask you to.
      </ModalParagraph>

      <ModalParagraph>
        You can cancel a Direct Debit at any time by simply contacting your bank or building society. Written
        confirmation may be required. Please also notify us.
      </ModalParagraph>
    </Stack>
    {completed ? (
      <Modal.Actions>
        <Button onClick={closeModal}>Done</Button>
      </Modal.Actions>
    ) : null}
  </Modal>
);

export default DirectDebitTerms;
