import { Button, List, ListItem, ListItemText, Stack } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { FullWidthContainer } from '../../styled';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

type Payload = {
  amountUsed: number;
  depositAmount: string;
  monthlyPayment: number;
};
type Props = ModalProps<Payload>;

const OverduePaymentSuccess = ({ confirmAction, payload }: Props) => {
  const { t } = useTranslation(['Modal', 'Common', 'Button']);
  const { depositAmount } = payload;

  return (
    <Modal title={t('OverduePaymentSuccess.title')}>
      <Stack>
        <ModalParagraph>{t('OverduePaymentSuccess.disclaimer')}</ModalParagraph>
        <FullWidthContainer>
          <List>
            <ListItem>
              <ListItemText text={t('OverduePaymentSuccess.info')} />
              <ListItemText text={t('Common:money', { amount: depositAmount })} />
            </ListItem>
          </List>
        </FullWidthContainer>
      </Stack>
      <Modal.Actions>
        <Button onClick={confirmAction}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OverduePaymentSuccess;
