import Layout from '../Layout';
import ErrorInfo from './ErrorInfo';

const ErrorFallback = () => (
  <Layout>
    <ErrorInfo browserNavigationEnabled />
  </Layout>
);

export default ErrorFallback;
