import { z } from 'zod';
import { moneySchema } from '../schemas';

export const getWithdrawalResponseSchema = z.object({
  withdrawnAmount: moneySchema,
  customerCosts: z.object({
    total: moneySchema,
    interest: moneySchema,
    principal: moneySchema,
    outstandingPrincipalBalance: moneySchema,
    totalPayments: z.number(),
    withdrawalFee: moneySchema.optional()
  }),
  companyCosts: z.object({
    total: moneySchema,
    funding: moneySchema,
    lossProvision: moneySchema,
    servicing: moneySchema,
    labour: moneySchema
  }),
  companyProfit: moneySchema
});
