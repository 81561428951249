import { Button, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { ModalProps } from './types';

const DirectPaymentSuccess = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button']);

  return (
    <Modal title={t('DirectPaymentSuccess.title')}>
      <Text as="span">{t('DirectPaymentSuccess.info')}</Text>
      <Modal.Actions>
        <Button onClick={closeModal}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DirectPaymentSuccess;
