import { AnchorButton, Button, Text } from '@fairlo/ui-kit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useIsEligibleForTwoMonthInsuranceCampaign from '../../../../hooks/useIsEligibleForTwoMonthInsuranceCampaign';
import usePPIPromoModalCooldownPeriod from '../../../../pages/Products/SE/views/Credit/hooks/usePPIPromoModalCooldownPeriod';
import useWithdrawNavigation from '../../../../pages/Products/SE/views/Credit/hooks/useWithdrawNavigation';
import { routeMap } from '../../../../routes/routeMap';
import Modal from '../../Modal';
import { ModalProps } from '../types';

const PaymentProtectionInsurancePromo = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button']);
  const { setCooldownPeriod } = usePPIPromoModalCooldownPeriod();
  const { navigateToWithdraw, isWithdrawNavigationLoading } = useWithdrawNavigation();
  const isEligibleForTwoMonthCampaign = useIsEligibleForTwoMonthInsuranceCampaign();

  useEffect(() => {
    setCooldownPeriod();
  }, [setCooldownPeriod]);

  const handleClick = () => {
    closeModal();
    navigateToWithdraw();
  };

  return (
    <Modal
      title={t(
        isEligibleForTwoMonthCampaign
          ? 'PaymentProtectionInsurancePromo.twoMonthCampaign.title'
          : 'PaymentProtectionInsurancePromo.title'
      )}
    >
      <Text>
        {t(
          isEligibleForTwoMonthCampaign
            ? 'PaymentProtectionInsurancePromo.twoMonthCampaign.info'
            : 'PaymentProtectionInsurancePromo.info'
        )}
      </Text>
      <Modal.Actions>
        <AnchorButton
          as={Link}
          to={
            isEligibleForTwoMonthCampaign
              ? routeMap.paymentProtectionInsurance.TwoMonthsFree.root
              : routeMap.paymentProtectionInsurance.root
          }
        >
          {t('PaymentProtectionInsurancePromo.insureButton')}
        </AnchorButton>
        <Button variant="outlined" disabled={isWithdrawNavigationLoading} onClick={handleClick}>
          {t('PaymentProtectionInsurancePromo.notInsureButton')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PaymentProtectionInsurancePromo;
