import * as applicants from './applicants';
import * as bankId from './bankId';
import * as credit from './credit';
import * as insurance from './insurance';
import * as login from './login';
import * as paymentProvider from './paymentProvider';

const SE = {
  applicants,
  bankId,
  credit,
  insurance,
  login,
  paymentProvider
};

export default SE;
