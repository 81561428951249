import { Accordion } from '@fairlo/ui-kit';
import { HTMLAttributes } from 'react';

import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../api/utils/queryKeys';
import { cmsApi } from '../../../integrations/sanity/api';
import AccordionStackItem from '../../AccordionStackItem';
import { PortableTextContent } from '../../shared';

const TermsAndConditionsAccordion = (props: Omit<HTMLAttributes<HTMLDivElement>, 'children'>) => {
  const { data: termsAndConditionsData } = useQuery({
    queryKey: queryKeys.cms.termsAndConditions,
    queryFn: cmsApi.getTermsAndConditions,
    staleTime: Infinity
  });

  if (!termsAndConditionsData) {
    return null;
  }

  return (
    <Accordion {...props}>
      {termsAndConditionsData.contentBlockGroup?.contentBlocks?.map(contentBlock => (
        <AccordionStackItem
          label={{ value: contentBlock.title ?? '', as: 'h2' }}
          spacing={24}
          key={contentBlock._key}
        >
          {!!contentBlock.body && <PortableTextContent value={contentBlock.body} />}
        </AccordionStackItem>
      ))}
    </Accordion>
  );
};

export default TermsAndConditionsAccordion;
