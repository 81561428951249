import { CreditTier, OverdueInfoResponse } from '../../types/credit';
import { environment } from '../../utils/env.utils';
import { ServerResponse } from '../types';
import { validateResponseData } from '../utils';
import axiosInstance, { createAxiosInstance } from '../utils/axiosInstance';
import { initiateTierMigrationResponseSchema } from './schemas';
import { CreditActionsResponse, CreditFeaturesResponse, CreditTierMigrationInfoResponse } from './types';

export const getCreditActions = async () => {
  const response = await axiosInstance.get<CreditActionsResponse>('/credit/actions');
  return response.data;
};

export const getOverdueInfo = async () => {
  const response = await axiosInstance.get<OverdueInfoResponse>('/credit/overdue');
  return response.data;
};

export const getFeatures = async () => {
  const response = await axiosInstance.get<CreditFeaturesResponse>('/credit/features-steps');
  return response.data;
};

export const getCreditTiers = async () => {
  const tempInstance = createAxiosInstance(environment.apiRoot);
  const response = await tempInstance.get<ServerResponse<CreditTier[]>>(`/credit/amount`);
  return response.data.data;
};

export const terminateCredit = ({ reason }: { reason: string }) =>
  axiosInstance.post<void>('/credit/terminate', { reason });

export const getCreditTierMigrationInfo = async () => {
  const response = await axiosInstance.get<CreditTierMigrationInfoResponse>('/credit/tier-migration');
  return response.data;
};

export const initiateTierMigration = async () => {
  const response = await axiosInstance.post('/credit/tier-migration');
  return validateResponseData(initiateTierMigrationResponseSchema, response.data);
};

export const migrateTier = async (tierMigrationId: string) => {
  await axiosInstance.post<void>(`/credit/tier-migration/${tierMigrationId}`);
};
