import { Modal as UIKitModal } from '@fairlo/ui-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';

type UIKitModalProps = React.ComponentProps<typeof UIKitModal>;
type Props = Omit<UIKitModalProps, 'closeButtonAriaLabel'>;

const Modal = ({ children, ...restProps }: Props) => {
  const { t } = useTranslation('Common');

  return (
    <UIKitModal closeButtonAriaLabel={t('close')} {...restProps}>
      {children}
    </UIKitModal>
  );
};

Modal.Root = UIKitModal.Root;
Modal.Actions = UIKitModal.Actions;

export default Modal;
