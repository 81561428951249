import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { LocalStorageKeys } from '../constants/LocalStorage';

type AuthTokenState = {
  authToken: string | null;
  setAuthToken: (value: string | null) => void;
  removeAuthToken: () => void;
};

export const useAuthTokenStore = create<AuthTokenState>()(
  persist(
    set => ({
      authToken: null,
      setAuthToken: value => set({ authToken: value }),
      removeAuthToken: () => set({ authToken: null })
    }),
    { name: LocalStorageKeys.AUTH_STORE }
  )
);

export const useAuthToken = () => useAuthTokenStore(state => state.authToken);

export const useAuthTokenActions = () => {
  const setAuthToken = useAuthTokenStore(state => state.setAuthToken);
  const removeAuthToken = useAuthTokenStore(state => state.removeAuthToken);

  return {
    setAuthToken,
    removeAuthToken
  };
};
