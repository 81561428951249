import { useState } from 'react';

import useInterval from './useInterval';

// Source: https://www.joshwcomeau.com/snippets/react-hooks/use-retry-until-resolved/
type Options = { interval?: number; enabled?: boolean };

function useRetryUntilResolved(callback: () => boolean, options: Options = {}) {
  const enabled = options.enabled ?? true;
  const interval = options.interval ?? 100;

  const [hasResolved, setHasResolved] = useState(false);

  useInterval(
    () => {
      const result = callback();
      if (result) {
        setHasResolved(true);
      }
    },
    hasResolved || !enabled ? null : interval
  );

  return hasResolved;
}

export default useRetryUntilResolved;
