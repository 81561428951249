import { AnchorButton, Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { routeMap } from '../../../../routes/routeMap';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

type Payload = {
  transactionId: string;
};

const WithdrawalSuccess = ({ payload, closeModal }: ModalProps<Payload>) => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('WithdrawalSuccess.title')}>
      <ModalParagraph>{t('WithdrawalSuccess.info')}</ModalParagraph>
      <Modal.Actions>
        <AnchorButton
          variant="outlined"
          color="neutral"
          as={Link}
          to={routeMap.getFairnessReceiptById(payload.transactionId)}
          onClick={closeModal}
        >
          {t('WithdrawalSuccess.fairnessReceiptButton')}
        </AnchorButton>
        <Button onClick={closeModal}>{t('WithdrawalSuccess.button')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WithdrawalSuccess;
