import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-use';
import { z } from 'zod';
import useLogout from '../../../../hooks/useLogout';
import { useAuthToken } from '../../../../store/authTokenStore';
import { parseJWT } from '../../../../utils/value';

const jwtSchema = z.object({
  exp: z.number()
});

// There is a case where a previously logged in user visits a landing page and
// the token has expired. At this point, there are no API calls that will
// return a 401 that forces a client logout and a cleanup of the current
// token. The result is that the header and the menu components render things
// that only a logged in user can see.
function useLogoutIfAuthTokenExpired() {
  const location = useLocation();
  const authToken = useAuthToken();
  const logout = useLogout();

  const expirationDate = useMemo(() => {
    if (!authToken) {
      return null;
    }

    const result = jwtSchema.safeParse(parseJWT(authToken));

    return result.success ? new Date(result.data.exp * 1000) : null;
  }, [authToken]);

  useEffect(() => {
    if (!expirationDate) {
      return;
    }

    if (expirationDate.getTime() < Date.now()) {
      logout({ keepCurrentUrl: true });
    }
  }, [location, expirationDate, logout]);
}

export default useLogoutIfAuthTokenExpired;
