import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { handleGlobalRequestError } from '../utils/handleGlobalRequestError';

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: handleGlobalRequestError
  }),
  queryCache: new QueryCache({
    onError: handleGlobalRequestError
  })
});
