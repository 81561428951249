import { z } from 'zod';
import { moneySchema } from '../../schemas';

export const getInsuranceResponseSchema = z
  .object({
    userId: z.string(),
    givenName: z.string(),
    premium: moneySchema,
    balance: moneySchema
  })
  .and(
    z.discriminatedUnion('status', [
      z.object({
        status: z.literal('Active'),
        contractId: z.string()
      }),
      z.object({
        status: z.enum(['ToBeInitialized', 'Expired', 'Terminated', 'Disabled']),
        contractId: z.undefined()
      })
    ])
  );

export const postInsuranceResponseSchema = z.object({ contractId: z.string() });

export const terminateInsuranceResponseSchema = z.object({
  status: z.enum(['Terminated', 'ToBeTerminated'])
});
