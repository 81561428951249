import { Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { useUserTransactionsQuery } from '../../../pages/Transactions/hooks/useUserTransactionsQuery';
import Animation from '../../Animation';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

const DirectDebitChargeInProgress = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button']);

  const { data: chargeInProgressAmount, isPending } = useUserTransactionsQuery({
    select: data =>
      data.reduce((sum, transaction) => (transaction.status === 'CHARGE' ? sum + transaction.amount : sum), 0)
  });

  if (isPending) {
    return null;
  }

  return (
    <Modal
      title={t('DirectDebitChargeInProgress.title')}
      topLeftContent={<Animation animation="timeHourglass" width="24px" />}
    >
      <ModalParagraph>
        {t('DirectDebitChargeInProgress.info', {
          chargeInProgressAmount
        })}
      </ModalParagraph>
      <Modal.Actions>
        <Button onClick={closeModal}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DirectDebitChargeInProgress;
