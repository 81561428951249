import { validateResponseData } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import {
  acceptBrokerApplicationOfferResponseSchema,
  createApplicationResponseSchema,
  getApplicationResponseSchema,
  signOfferResponseSchema,
  startApplicationResponseSchema
} from './schemas';
import { CreateApplicationPayload, SignBrokerApplicationOfferPayload } from './types';

export const createApplication = async (payload: CreateApplicationPayload) => {
  const response = await axiosInstance.post('/se/credit/applications', payload);

  return validateResponseData(createApplicationResponseSchema, response.data);
};

export const startApplication = async (applicationId: string) => {
  const response = await axiosInstance.post(`/se/credit/applications/${applicationId}`);

  return validateResponseData(startApplicationResponseSchema, response.data);
};

export const signOffer = async (applicationId: string) => {
  const response = await axiosInstance.post(`/se/credit/applications/${applicationId}/sign-offer`);

  return validateResponseData(signOfferResponseSchema, response.data);
};

export const acceptOffer = async (applicationId: string) => {
  await axiosInstance.post<void>(`/se/credit/applications/${applicationId}/accept-offer`);
};

export const getApplication = async (applicationId: string) => {
  const response = await axiosInstance.get(`/se/credit/applications/${applicationId}`);

  return validateResponseData(getApplicationResponseSchema, response.data);
};

export const signBrokerApplicationOffer = async ({
  applicationId,
  ...payload
}: SignBrokerApplicationOfferPayload) => {
  const response = await axiosInstance.post(`/se/credit/applications/${applicationId}/sign-offer`, payload);

  return validateResponseData(signOfferResponseSchema, response.data);
};

export const acceptBrokerApplicationOffer = async (applicationId: string) => {
  const response = await axiosInstance.post(`/se/credit/applications/${applicationId}/accept-offer`);

  return validateResponseData(acceptBrokerApplicationOfferResponseSchema, response.data);
};
