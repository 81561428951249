import { Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import useMeQuery from '../../../hooks/useMeQuery';
import { selectCreditAmountNotUsed } from '../../../hooks/useMeQuery/selectors';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

const WithdrawalNotPossible = ({ confirmAction }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button']);
  const { data: amountNotUsed } = useMeQuery({
    select: selectCreditAmountNotUsed
  });

  return (
    <Modal title={t('WithdrawalNotPossible.title')}>
      <ModalParagraph>{t('WithdrawalNotPossible.info', { amountNotUsed })}</ModalParagraph>
      <Modal.Actions>
        <Button onClick={confirmAction}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WithdrawalNotPossible;
