import { Button, List, ListItem, ListItemText } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { WithdrawFeaturesStep } from '../../../api/credit/types';
import useWithdrawMutation from '../../../pages/Products/components/features/GenericWithdraw/hooks/useWithdrawMutation';
import { FullWidthContainer } from '../../styled';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

type Payload = {
  withdrawal: WithdrawFeaturesStep;
};
type Props = ModalProps<Payload>;

const ConfirmWithdrawal = ({ payload, closeModal }: Props) => {
  const { t } = useTranslation(['Modal', 'Common']);
  const {
    withdrawal: { monthlyAmount, stepAmount, principalLeft, withdrawalFee }
  } = payload;
  const { mutate: withdraw } = useWithdrawMutation();

  const handleClick = () => {
    withdraw(stepAmount);
    closeModal();
  };

  return (
    <Modal title={t('ConfirmWithdrawal.title', { withdrawalAmount: stepAmount })}>
      <ModalParagraph>
        {withdrawalFee !== undefined
          ? t('ConfirmWithdrawal.infoWithFees', { withdrawalFee })
          : t('ConfirmWithdrawal.info')}
      </ModalParagraph>
      <FullWidthContainer>
        <List>
          <ListItem>
            <ListItemText text={t('ConfirmWithdrawal.infoTitle1')} />
            <ListItemText text={t('Common:money', { amount: monthlyAmount })} />
          </ListItem>
          <ListItem>
            <ListItemText text={t('ConfirmWithdrawal.infoTitle2')} />
            <ListItemText text={t('Common:money', { amount: principalLeft })} />
          </ListItem>
        </List>
      </FullWidthContainer>
      <Modal.Actions>
        <Button onClick={handleClick}>
          {t('ConfirmWithdrawal.button', { withdrawalAmount: stepAmount })}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmWithdrawal;
