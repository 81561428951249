import { Stack, Text } from '@fairlo/ui-kit';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { queryKeys } from '../../../api/utils/queryKeys';
import { cmsApi } from '../../../integrations/sanity/api';
import { PortableTextContent } from '../../shared';
import Modal from '../Modal';

const BodyContent = styled.div`
  strong {
    font-weight: bold;
  }

  & > * + * {
    margin-top: 8px;
  }
`;

const SekkiCredit = () => {
  const { data: sekkiData } = useQuery({
    queryKey: queryKeys.cms.sekki,
    queryFn: cmsApi.getSekki,
    staleTime: Infinity
  });

  if (!sekkiData) {
    return null;
  }

  return (
    <Modal title="Sekki">
      <Stack>
        {sekkiData?.contentBlockGroup?.contentBlocks?.map(contentBlock => (
          <div key={contentBlock._key}>
            <Stack spacing={16}>
              <Text as="h3" size="m">
                {contentBlock.title}
              </Text>
              <BodyContent>
                {!!contentBlock.body && <PortableTextContent value={contentBlock.body} />}
              </BodyContent>
            </Stack>
          </div>
        ))}
      </Stack>
    </Modal>
  );
};

export default SekkiCredit;
