import { useQuery } from '@tanstack/react-query';
import API, { CreditFeaturesResponse, DebtConsolidationFeaturesResponse } from '../../../../api';
import { queryKeys } from '../../../../api/utils/queryKeys';
import { ProductName, constants } from '../../../../constants';
import { CustomUseQueryOptions } from '../../../../types/reactQuery';

type Product = ProductName.CREDIT | ProductName.DEBT_CONSOLIDATION;

const defaultOptions = {
  staleTime: constants.EXTENDED_STALE_TIME
};

export const useCreditFeaturesQuery = <TData = CreditFeaturesResponse>(
  options?: CustomUseQueryOptions<CreditFeaturesResponse, TData>
) =>
  useQuery({
    queryKey: queryKeys.creditFeatures,
    queryFn: API.credit.getFeatures,
    ...defaultOptions,
    ...options
  });

export const useDebtConsolidationFeaturesQuery = <TData = DebtConsolidationFeaturesResponse>(
  options?: CustomUseQueryOptions<DebtConsolidationFeaturesResponse, TData>
) =>
  useQuery({
    queryKey: queryKeys.debtConsolidationFeatures,
    queryFn: API.debtConsolidation.getFeatures,
    ...defaultOptions,
    ...options
  });

const UseFeaturesQueryMap = {
  [ProductName.CREDIT]: useCreditFeaturesQuery,
  [ProductName.DEBT_CONSOLIDATION]: useDebtConsolidationFeaturesQuery
} satisfies Record<Product, typeof useCreditFeaturesQuery | typeof useDebtConsolidationFeaturesQuery>;

export default UseFeaturesQueryMap;
