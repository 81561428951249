import { size } from '@fairlo/ui-kit';
import React from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';

// Inspiration: https://material-ui.com/customization/breakpoints/#css-media-queries
export const useIsTabletDown = () => useMediaQuery({ maxWidth: size.tablet });

export const Desktop = (props: Omit<React.ComponentProps<typeof MediaQuery>, 'minWidth'>) => (
  <MediaQuery minWidth={size.tablet} {...props} />
);

export const Mobile = (props: Omit<React.ComponentProps<typeof MediaQuery>, 'maxWidth'>) => (
  <MediaQuery maxWidth={size.tablet} {...props} />
);
