import { withDevCycleProvider } from '@devcycle/react-client-sdk';
import * as Sentry from '@sentry/react';
import { LocalStorageKeys } from '../../constants/LocalStorage';
import ChatWidgetScript from '../../integrations/chat/ChatWidgetScript';
import { useLogRocketSessionInSentry } from '../../integrations/logRocket';
import useInitializeSmartlookOptions from '../../integrations/smartlook';
import Pages from '../../routes/Pages';
import { environment } from '../../utils/env.utils';
import useStoreTrackingData from '../../utils/tracking/useStoreTrackingData';
import ErrorFallback from '../error/ErrorFallback';
import Html from '../Html';
import ModalRenderer from '../ModalRenderer';
import PaymentProviderProvider from '../PaymentProvider/PaymentProviderProvider';
import { ToastContainer } from '../Toast';
import useHandleLocationChange from './hooks/useHandleLocationChange';
import useHandleUserTracking from './hooks/useHandleUserTracking';
import useLogoutIfAuthTokenExpired from './hooks/useLogoutIfAuthTokenExpired';
import useScrollTop from './hooks/useScrollTop';

function App() {
  useHandleUserTracking();
  useLogoutIfAuthTokenExpired();
  useStoreTrackingData();
  useScrollTop();
  useHandleLocationChange();
  useInitializeSmartlookOptions();
  useLogRocketSessionInSentry();

  return (
    <>
      <ChatWidgetScript />

      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <PaymentProviderProvider>
          <Html />
          <ToastContainer limit={1} />
          <ModalRenderer />
          <Pages />
        </PaymentProviderProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}

const userId = localStorage.getItem(LocalStorageKeys.USER_ID);
const AppWithDevCycleProvider = withDevCycleProvider({
  sdkKey: environment.dvcSdkKey,
  user: userId ? { user_id: userId } : undefined
})(App);

export default AppWithDevCycleProvider;
