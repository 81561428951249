import ApplicationExistingUser from './ApplicationExistingUser';
import ApplicationExpired from './ApplicationExpired';
import BankAccountManualApproval from './BankAccountManualApproval';
import BankAccountNotSupported from './BankAccountNotSupported';
import BankAccountUnableToValidate from './BankAccountUnableToValidate';
import ConfirmEndAgreement from './ConfirmEndAgreement';
import ConfirmManualDeposit from './ConfirmManualDeposit';
import DepositFail from './DepositFail';
import DirectDebitSetupFailed from './DirectDebitSetupFailed';
import DirectDebitTerms from './DirectDebitTerms';
import OverduePayment from './OverduePayment';
import PrivacyNotice from './PrivacyNotice';
import Reapply from './Reapply';
import TermsAndConditions from './TermsAndConditions';
import WithdrawalSuccess from './WithdrawalSuccess';

const UK = {
  APPLICATION_EXISTING_USER: ApplicationExistingUser,
  APPLICATION_EXPIRED: ApplicationExpired,
  BANK_ACCOUNT_MANUAL_APPROVAL: BankAccountManualApproval,
  BANK_ACCOUNT_NOT_SUPPORTED: BankAccountNotSupported,
  BANK_ACCOUNT_UNABLE_TO_VALIDATE: BankAccountUnableToValidate,
  CONFIRM_END_AGREEMENT: ConfirmEndAgreement,
  CONFIRM_MANUAL_DEPOSIT: ConfirmManualDeposit,
  DEPOSIT_FAIL: DepositFail,
  DIRECT_DEBIT_SETUP_FAILED: DirectDebitSetupFailed,
  DIRECT_DEBIT_TERMS: DirectDebitTerms,
  PRIVACY_NOTICE: PrivacyNotice,
  REAPPLY: Reapply,
  TERMS_AND_CONDITIONS: TermsAndConditions,
  WITHDRAWAL_SUCCESS: WithdrawalSuccess,
  OVERDUE_PAYMENT: OverduePayment
} as const;

export default UK;
