import { useQuery } from '@tanstack/react-query';
import API, { MeResponse } from '../../api';
import { queryKeys } from '../../api/utils/queryKeys';
import { CustomUseQueryOptions } from '../../types/reactQuery';

const useMeQuery = <TData = MeResponse>(options?: CustomUseQueryOptions<MeResponse, TData>) =>
  useQuery({
    queryKey: queryKeys.me,
    queryFn: API.me.getMe,
    staleTime: Infinity,
    ...options
  });

export default useMeQuery;
