import { Text } from '@fairlo/ui-kit';
import { PortableText, PortableTextProps } from '@portabletext/react';
import { ComponentProps } from 'react';
import styled, { WebTarget } from 'styled-components';
import { CmsLink, CmsLinkProjectionType } from './CmsLink';

const StyledText = styled(Text)`
  margin-bottom: 8px;
`;

type LegendProps = ComponentProps<typeof Text> & {
  as?: WebTarget;
};

export const Legend = (props: LegendProps) => <StyledText as="legend" {...props} />;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
    border: 1px solid black;
  }
`;

export const TableMark = ({ value }: { value?: { rows?: { cells: string[] }[] } }) => {
  if (!value) {
    return null;
  }

  return (
    <div style={{ overflowX: 'auto' }}>
      <StyledTable>
        <thead>
          <tr>{value.rows?.[0]?.cells.map((cell, index) => <th key={index}>{cell}</th>)}</tr>
        </thead>
        <tbody>
          {value.rows?.slice(1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.cells.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};

export const PortableTextContent = ({ value }: Pick<PortableTextProps, 'value'>) => (
  <PortableText
    value={value}
    components={{
      block: {
        h3: ({ children }) => <Text as="h3">{children}</Text>,
        h4: ({ children }) => <Text as="h4">{children}</Text>,
        h5: ({ children }) => (
          <Text as="h5" size="xs">
            {children}
          </Text>
        ),
        normal: ({ children }) => <Text>{children}</Text>
      },
      listItem: ({ children }) => (
        <Text as="li" mb={8}>
          {children}
        </Text>
      ),
      marks: {
        link: ({ children, value }) => <CmsLink link={value as CmsLinkProjectionType}>{children}</CmsLink>,
        table: TableMark
      }
    }}
  />
);
