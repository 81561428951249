export const routeMap = {
  home: '/',
  login: {
    root: '/login',
    SE: {
      bankid: '/login/bankid'
    },
    UK: {
      accountLocked: '/login/account-locked',
      authenticate: '/login/authenticate',
      manualApproval: '/login/manual-approval'
    }
  },
  credit: {
    root: '/my-credit',
    deposit: {
      root: '/my-credit/deposit',
      UK: {
        summary: '/my-credit/deposit/summary'
      }
    },
    monthlyPayment: '/my-credit/monthly-payment',
    withdrawal: '/my-credit/withdrawal',
    tierMigration: {
      root: '/my-credit/tier-migration',
      id: {
        getBankId: (id = ':id') => `/my-credit/tier-migration/${id}/bankid`
      }
    }
  },
  debtConsolidation: {
    root: '/my-collected-loans',
    transfer: '/my-collected-loans/transfer',
    deposit: '/my-collected-loans/deposit',
    monthlyPayment: '/my-collected-loans/monthly-payment'
  },
  selectAccount: '/select-account',
  bankAccount: {
    root: '/bank-account',
    UK: {
      setup: '/bank-account/setup',
      directDebit: {
        root: '/bank-account/direct-debit',
        repaymentDate: '/bank-account/direct-debit/repayment-date'
      }
    }
  },
  userProfile: {
    root: '/user-profile',
    SE: {
      accountDetails: '/user-profile/account-details',
      financialDetails: '/user-profile/financial-details'
    },
    UK: {
      contactInformation: '/user-profile/contact-information',
      endAgreement: '/user-profile/end-agreement'
    }
  },
  transactions: {
    root: '/transactions',
    id: {
      getRoot: (transactionId = ':transactionId') => `/transactions/${transactionId}`
    }
  },
  noActiveCredit: '/no-credit',
  levlaApply: '/levla/apply',
  paymentProtectionInsurance: {
    root: '/ppi',
    token: {
      getRoot: (token = ':token') => `/ppi/${token}`
    },
    TwoMonthsFree: {
      root: '/ppi/two-months-free',
      token: {
        getRoot: (token = ':token') => `/ppi/two-months-free/${token}`
      }
    }
  },
  notFound: '/not-found',
  application: {
    root: '/application',
    SE: {
      personalDetails: '/application/personal-details',
      employmentStatus: '/application/employment-status',
      paymentProtectionInsurance: '/application/payment-protection-insurance',
      finances: '/application/finances',
      pending: '/application/pending',
      id: {
        getBankId: (applicationId = ':applicationId') => `/application/${applicationId}/bankid`,
        getStatus: (applicationId = ':applicationId') => `/application/${applicationId}/status`,
        levla: {
          getBankId: (applicationId = ':applicationId') => `/application/${applicationId}/levla/bankid`
        }
      }
    },
    UK: {
      address: '/application/address',
      income: '/application/income',
      outgoings: '/application/outgoings',
      status: {
        root: '/application/status',
        rejection: '/application/status/rejection',
        manualApproval: '/application/status/manual-approval'
      },
      creditLimit: '/application/credit-limit',
      mobileVerification: {
        root: '/application/mobile-verification',
        verify: '/application/mobile-verification/verify',
        rejection: '/application/mobile-verification/rejection'
      },
      documents: {
        root: '/application/documents',
        summary: '/application/documents/summary',
        creditAgreement: '/application/documents/credit-agreement',
        creditInformation: '/application/documents/credit-information'
      },
      success: '/application/success',
      accountLocked: '/application/account-locked'
    }
  },
  directPayment: '/direct-payment',
  getFairnessReceiptById: (transactionId = ':transactionId') => `/fairness-receipt/${transactionId}`,
  SE: {
    brokerApplication: {
      id: {
        getRoot: (applicationId = ':applicationId') => `/broker-application/${applicationId}`,
        getBankId: (applicationId = ':applicationId') => `/broker-application/${applicationId}/bankid`
      }
    }
  },
  UK: {
    depositResult: '/deposit-result'
  }
};
