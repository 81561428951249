export enum CreditRejectReasons {
  BANKID_ISSUE_DATE = 'BankIDIssueDate',
  BISNODE_REJECTED = 'BisnodeRejected',
  EXCEED_NUMBER_OF_LOANS = 'ExceedNumberOfLoans',
  EXISTING_CREDIT = 'ExistingCredit',
  EXISTING_LOAN = 'ExistingLoan',
  KALP_REJECTED = 'KalpRejected',
  OVERDUE_PAYMENTS = 'OverduePayments',
  RECENT_BISNODE_CALL = 'RecentBisnodeCall',
  TERMINATED_AGREEMENT = 'TerminatedAgreement',
  TERMINATED_LOAN = 'TerminatedLoan',
  UC_SCORE_MISSING = 'UCScoreMissing',
  UC_SERVICE_ERROR = 'UCServiceError',
  USER_BLOCKED = 'UserBlocked'
}

export enum LevlaRejectReasons {
  DIRECT_DEBIT_MANDATE_NOT_FOUND = 'DirectDebitMandateNotFound',
  ELIGIBILITY_CHECKER_REJECTED = 'EligibilityCheckerRejected',
  INTERNAL_SCORE = 'InternalScore',
  MAX_PRODUCT = 'MaxProduct',
  ONGOING_CHARGE = 'OngoingCharge',
  OVERDUE_PAYMENTS = 'OverduePayments',
  PAYMENTS_WITH_FEES = 'PaymentsWithFees',
  RECENT_BISNODE_CALL = 'RecentBisnodeCall',
  TOO_MANY_INQUIRIES = 'TooManyInquiries',
  UC_SCORE_MISSING = 'UCScoreMissing',
  UC_SERVICE_ERROR = 'UCServiceError',
  PAYMENT_FREE_MONTH = 'PaymentFreeMonth'
}
