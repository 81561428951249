import { Colors, Heading, Text } from '@fairlo/ui-kit';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  //Resets' inspiration: https://www.joshwcomeau.com/css/custom-css-reset/#the-css-reset
  html, body {
    height: 100%;
  }

  img {
    max-width: 100%;
  }

  ${Text} a {
    // Use same color links as the text
    color: inherit;
  }

  a ${Heading} {
    color: ${Colors.TEXT};
  }
`;

export default GlobalStyle;
