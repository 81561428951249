import axiosInstance from '../../utils/axiosInstance';
import {
  DirectPaymentResponse,
  DirectPaymentStatusResponse,
  InitiateDirectPaymentPayload,
  InitiateDirectPaymentResponse,
  SelectAccountPayload,
  SelectAccountResponse,
  UpdateAccountPayload,
  UpdateAccountResponse
} from './types';

export const selectAccount = async (payload: SelectAccountPayload) => {
  const response = await axiosInstance.post<SelectAccountResponse>('/payment-provider/account', payload);
  return response.data.response;
};

export const updateAccount = async (payload: UpdateAccountPayload) => {
  const response = await axiosInstance.patch<UpdateAccountResponse>('/payment-provider/account', payload);
  return response.data.response;
};

export const getDirectPayment = async (paymentId: string) => {
  const response = await axiosInstance.get<DirectPaymentResponse>(
    `/payment-provider/direct-payment/${paymentId}`
  );
  return response.data;
};

export const initiateDirectPayment = async (paymentId: string, payload: InitiateDirectPaymentPayload) => {
  const response = await axiosInstance.post<InitiateDirectPaymentResponse>(
    `/payment-provider/direct-payment/${paymentId}/payment-request`,
    payload
  );
  return response.data.paymentProviderToken;
};

export const getDirectPaymentStatus = async (paymentId: string) => {
  const response = await axiosInstance.get<DirectPaymentStatusResponse>(
    `/payment-provider/direct-payment/${paymentId}/payment-request`
  );
  return response.data;
};

export const abortDirectPayment = async (paymentId: string) => {
  const response = await axiosInstance.delete<''>(`payment-provider/direct-payment/${paymentId}`);

  return response.data;
};
