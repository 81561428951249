import { environment } from '../utils/env.utils';

export const CountryConfig = {
  SE: {
    currency: 'SEK',
    locale: 'sv-SE'
  },
  UK: {
    currency: 'GBP',
    locale: 'en-GB'
  }
} as const;

export type Country = keyof typeof CountryConfig;
export type Currency = typeof CountryConfig[Country]['currency'];
export type Locale = typeof CountryConfig[Country]['locale'];

// TODO: All this constants should be changed to functions
export const DEFAULT_COUNTRY: Country = 'SE';

export const DEFAULT_LOCALE: Locale = CountryConfig[environment.country].locale;
export const DEFAULT_CURRENCY: Currency = CountryConfig[environment.country].currency;

export const LOCALES = Object.values(CountryConfig).map(({ locale }) => locale);

export const getCurrentCurrency = () => CountryConfig[environment.country].currency;

export const COUNTRY_MAP_LOCALE: Record<Country, Locale> = {
  SE: 'sv-SE',
  UK: 'en-GB'
} as const;
