import * as applicants from './applicants';
import * as credit from './credit';
import * as directDebit from './directDebit';
import * as me from './me';
import * as paymentProvider from './paymentProvider';

const UK = {
  applicants,
  credit,
  directDebit,
  me,
  paymentProvider
};

export default UK;
