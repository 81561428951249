import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import english from './assets/english';
import swedish from './assets/swedish';
import { DEFAULT_LOCALE, Locale, LOCALES } from './constants';
import { formatCurrency } from './currency';

const resources: Record<Locale, typeof swedish | typeof english> = {
  'sv-SE': swedish,
  'en-GB': english
} as const;

const initializeI18n = (defaultLocale: Locale = DEFAULT_LOCALE) => {
  void i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      lng: defaultLocale,
      fallbackLng: defaultLocale,
      supportedLngs: LOCALES,
      resources,
      interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      }
    });

  i18n.services.formatter?.add('currency', (value, lng, options) => formatCurrency(value, options));

  return i18n;
};

export default initializeI18n;
