import { AxiosRequestConfig } from 'axios';

import { ServerResponse } from '../types';
import { validateResponseData } from '../utils';
import axiosInstance from '../utils/axiosInstance';
import { getWithdrawalResponseSchema } from './schemas';
import {
  DepositResponse,
  PayOverdueResponse,
  PendingTransactionResponse,
  SEDepositPayload,
  SEPayOverduePayload,
  UKDepositPayload,
  UKPayOverduePayload,
  WithdrawResponse
} from './types';

export const payExtra = async (payload: SEDepositPayload | UKDepositPayload) => {
  const response = await axiosInstance.post<DepositResponse>('/payment-provider/payment/extra', payload);

  return response.data.paymentProviderUrl;
};

export const completeLoan = async (payload: SEDepositPayload | UKDepositPayload) => {
  const response = await axiosInstance.post<DepositResponse>(
    '/payment-provider/payment/complete-loan',
    payload
  );

  return response.data.paymentProviderUrl;
};

export const withdraw = async (amount: number, config?: AxiosRequestConfig) => {
  const response = await axiosInstance.post<WithdrawResponse>(
    '/payment-provider/withdrawal',
    { amount },
    config
  );
  return response.data;
};

export const payOverdue = async (payload: SEPayOverduePayload | UKPayOverduePayload) => {
  const response = await axiosInstance.post<PayOverdueResponse>('/payment-provider/payment/overdue', payload);

  return response.data.paymentProviderUrl;
};

export const getPendingTransaction = async () => {
  const response = await axiosInstance.get<ServerResponse<PendingTransactionResponse>>(
    '/payment-provider/pending-transaction'
  );
  return response.data.data;
};

export const getWithdrawalById = async (transactionId: string) => {
  const response = await axiosInstance.get(`/payment-provider/withdrawal/${transactionId}`);
  return validateResponseData(getWithdrawalResponseSchema, response.data);
};
