import { useTranslation } from 'react-i18next';

import { routeMap } from '../../../../routes/routeMap';
import Modal from '../../Modal';
import { ModalProps } from '../types';
import { Link } from 'react-router-dom';
import { AnchorButton } from '@fairlo/ui-kit';

const Reapply = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation('Modal');

  return (
    <Modal title={t('Reapply.title')}>
      <Modal.Actions>
        <AnchorButton as={Link} to={routeMap.application.root} onClick={closeModal}>
          {t('Reapply.button')}
        </AnchorButton>
      </Modal.Actions>
    </Modal>
  );
};

export default Reapply;
