import groq from 'groq';

export const LINK_PROJECTION = groq`{
    _key,
    _type,
    type,
    email,
    url,
    queryParameters,
    openInNewTab,
    internal->{_type, slug}
  }`;
export const CONTENT_BLOCK_BODY_PROJECTION = groq`{
    ...,
    markDefs[] {
      ...,
      _type == 'link' => ${LINK_PROJECTION}
    }
  }`;

export const CONTENT_BLOCK_PROJECTION = groq`{
    _key,
    _type,
    title,
    body[] ${CONTENT_BLOCK_BODY_PROJECTION}
  }`;
export const CONTENT_BLOCK_GROUP_PROJECTION = groq`{
    _key,
    _type,
    title,
    contentBlocks[] ${CONTENT_BLOCK_PROJECTION}
  }`;
