import { z } from 'zod';
import { sessionStorageKeys } from '../../constants/sessionStorageKeys';

const utmTagDataSchema = z.object({
  utm_campaign: z.string().optional(),
  utm_source: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_term: z.string().optional(),
  utm_content: z.string().optional(),
  utm_custom_tags: z.record(z.string()).optional()
});

export type UtmTagData = z.infer<typeof utmTagDataSchema>;

const storePurchaseTrackingId = (purchaseTrackingId: string) => {
  sessionStorage.setItem(sessionStorageKeys.purchaseTrackingId, purchaseTrackingId);
};

const getPurchaseTrackingId = () =>
  sessionStorage.getItem(sessionStorageKeys.purchaseTrackingId) ?? undefined;

const clearPurchaseTrackingId = () => {
  sessionStorage.removeItem(sessionStorageKeys.purchaseTrackingId);
};

const storeUtmTags = (utmTags: UtmTagData) => {
  sessionStorage.setItem(sessionStorageKeys.utmTags, JSON.stringify(utmTags));
};

const getUtmTags = () => {
  const stringifiedTags = sessionStorage.getItem(sessionStorageKeys.utmTags);

  if (!stringifiedTags) {
    return undefined;
  }

  try {
    return utmTagDataSchema.parse(JSON.parse(stringifiedTags));
  } catch {
    return undefined;
  }
};

const clearUtmTags = () => {
  sessionStorage.removeItem(sessionStorageKeys.utmTags);
};

export const getTrackingData = () => ({
  purchaseTrackingId: getPurchaseTrackingId(),
  utmTags: getUtmTags()
});

export const storeTrackingData = ({
  purchaseTrackingId,
  utmTags
}: {
  purchaseTrackingId?: string;
  utmTags?: UtmTagData;
}) => {
  if (purchaseTrackingId) {
    storePurchaseTrackingId(purchaseTrackingId);
  }

  if (utmTags) {
    storeUtmTags(utmTags);
  }
};

export const clearTrackingData = () => {
  clearPurchaseTrackingId();
  clearUtmTags();
};
