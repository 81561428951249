import axios, { AxiosInstance } from 'axios';

import { showErrorToast } from '../../components/Toast';
import { ResponseStatus } from '../../constants';
import { useAuthTokenStore } from '../../store/authTokenStore';
import { environment } from '../../utils/env.utils';

/**
 * Given an errorId, it returns the translation key that this errorId corresponds to
 */
const getI18nErrorMessageKey = (errorId: number) => {
  if (errorId === 0) {
    return 'bankId';
  } else if (errorId === 1) {
    return 'loginTookLong';
    // errorId 3, 17 was removed from this array because we should handle it per case and not globally
  } else if ([2, 4, 5, 6, 11, 12, 13, 14, 15, 16, 18].includes(errorId)) {
    return 'somethingWentWrong';
  } else if ([7, 8, 9, 10].includes(errorId)) {
    return 'inactivity';
  }
};

export const createAxiosInstance = (apiUrl: string): AxiosInstance => {
  const instance = axios.create({
    baseURL: apiUrl,
    headers: {
      Accept: 'application/json'
    }
  });

  instance.interceptors.request.use(config => {
    const token = useAuthTokenStore.getState().authToken;
    if (token && config.headers) {
      config.headers.Authorization = token;
    }

    return {
      ...config
    };
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        const errorId = error.response.data.error?.errorId;
        const errorKey = getI18nErrorMessageKey(errorId);
        if (errorKey) {
          showErrorToast(`ApiErrors:${errorKey}`);
        }

        if (
          error.response.status === ResponseStatus.CODE_401 ||
          error.response.status === ResponseStatus.CODE_403
        ) {
          useAuthTokenStore.getState().removeAuthToken();
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const axiosInstance: AxiosInstance = createAxiosInstance(environment.apiRoot);

export default axiosInstance;
