import { Button, List, ListItem, ListItemText, Stack } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { SEPayOverduePayload } from '../../../../api';
import { QueryParam, QueryParamValue } from '../../../../constants';
import { usePayOverdueMutation } from '../../../../pages/Products/components/cards/OverdueCard/usePayOverdueMutation';
import { routeMap } from '../../../../routes/routeMap';
import { OverdueInfoResponse } from '../../../../types/credit';
import { constructURL } from '../../../../utils/url';
import { FullWidthContainer } from '../../../styled';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

type Payload = {
  overdueInfo: Pick<OverdueInfoResponse, 'totalOverdue'>;
};
type Props = ModalProps<Payload>;

export const getOverduePaymentReturnUrl = ({
  isSuccessful,
  amount
}: {
  isSuccessful: boolean;
  amount: number;
}) =>
  constructURL(routeMap.credit.root, {
    queryParams: {
      [QueryParam.DEPOSIT_AMOUNT]: `${amount}`,
      [QueryParam.STATUS]: isSuccessful
        ? QueryParamValue.PAY_OVERDUE_SUCCESS
        : QueryParamValue.PAY_OVERDUE_FAIL
    }
  });

const OverduePayment = ({ closeModal, payload }: Props) => {
  const { t } = useTranslation(['Modal', 'Common']);
  const {
    overdueInfo: { totalOverdue }
  } = payload;

  const overduePayload: SEPayOverduePayload = {
    amount: totalOverdue.amount,
    successfulUrl: getOverduePaymentReturnUrl({ isSuccessful: true, amount: totalOverdue.amount }),
    failedUrl: getOverduePaymentReturnUrl({ isSuccessful: false, amount: totalOverdue.amount })
  };

  const { mutate: payOverdue, isPending: isPayOverduePending } = usePayOverdueMutation({
    onSettled: closeModal
  });

  return (
    <Modal title={t('OverduePayment.title')}>
      <Stack>
        <ModalParagraph>{t('OverduePayment.info', totalOverdue)}</ModalParagraph>
        <FullWidthContainer>
          <List>
            <ListItem>
              <ListItemText text={t('OverduePayment.infoItemTitle')} />
              <ListItemText text={t('Common:money', totalOverdue)} />
            </ListItem>
          </List>
        </FullWidthContainer>
      </Stack>
      <Modal.Actions>
        <Button color="alert" loading={isPayOverduePending} onClick={() => payOverdue(overduePayload)}>
          {t('OverduePayment.button', totalOverdue)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OverduePayment;
