import { isAxiosError } from 'axios';
import { showErrorToast } from '../../components/Toast';
import { ErrorId } from '../../constants';

import { t } from 'i18next';
import { apiErrorSchema } from '../error';

export const handleGlobalRequestError = (error: unknown) => {
  const result = apiErrorSchema.safeParse(error);

  if (result.success) {
    //error from our BE
    const backendError = result.data;
    if (
      (backendError.status === 500 && backendError.errorId === ErrorId.INTERNAL_SERVER_ERROR) ||
      (backendError.status === 429 && backendError.errorId === ErrorId.WAF_BLOCKED) //RateLimitExceeded error from WAF
    ) {
      showErrorToast(t('ErrorToast:defaultMessage'));
    }
  } else if (isAxiosError(error) && error.request.status >= 500) {
    //external service error other than our BE
    showErrorToast(t('ErrorToast:defaultMessage'));
  }
};
