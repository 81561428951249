import axiosInstance from '../utils/axiosInstance';
import { GetMobileVerificationResponse } from './types';

export const sendMobileVerificationCode = async (mobileNumber: string) => {
  const response = await axiosInstance.post<{ codeTriesLeft: number }>('/mobile-verification/send', {
    mobile: mobileNumber
  });
  return response.data;
};

export const verifyMobileVerificationCode = async (verificationCode: string) => {
  const response = await axiosInstance.post<''>('/mobile-verification/verify', {
    code: verificationCode
  });
  return response.data;
};

export const getMobileVerification = async () => {
  const response = await axiosInstance.get<GetMobileVerificationResponse>('/mobile-verification');
  return response.data;
};
