import { validateResponseData } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import {
  getInsuranceResponseSchema,
  postInsuranceResponseSchema,
  terminateInsuranceResponseSchema
} from './schemas';

export const getInsurance = async (token?: string) => {
  const apiURL = token ? `/se/insurance?token=${token}` : '/se/insurance';
  const response = await axiosInstance.get(apiURL);
  return validateResponseData(getInsuranceResponseSchema, response.data);
};

export const postInsurance = async (token?: string) => {
  const response = await axiosInstance.post('/se/insurance', { token });
  return validateResponseData(postInsuranceResponseSchema, response.data);
};

export const terminateInsurance = async (contractId: string) => {
  const response = await axiosInstance.post(`/se/insurance/${contractId}/terminate`);
  return validateResponseData(terminateInsuranceResponseSchema, response.data);
};
