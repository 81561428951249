import { z } from 'zod';

export const initiateLoginResponseSchema = z.object({
  orderId: z.string(),
  autoStartToken: z.string()
});

export const getLoginDataResponseSchema = z.object({
  authToken: z.string()
});
