import { Button, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { ModalProps } from '../types';

const PaymentProtectionInsuranceToBeTerminated = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation('Modal', { keyPrefix: 'PaymentProtectionInsuranceToBeTerminated' });

  return (
    <Modal title={t('title')}>
      <Text>{t('info')}</Text>
      <Modal.Actions>
        <Button onClick={closeModal}>{t('button')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PaymentProtectionInsuranceToBeTerminated;
