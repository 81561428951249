import { validateResponseData } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { getLoginDataResponseSchema, initiateLoginResponseSchema } from './schemas';

export const initiateLogin = async (nationalNumber?: string | void) => {
  const response = await axiosInstance.post('/se/login', { nationalNumber });

  return validateResponseData(initiateLoginResponseSchema, response.data);
};

export const getLoginData = async (orderId: string) => {
  const response = await axiosInstance.get(`/se/login/${orderId}`);

  return validateResponseData(getLoginDataResponseSchema, response.data);
};
