import { useQuery } from '@tanstack/react-query';
import API from '../../../../../../../../api';
import { GetPaymentResponse } from '../../../../../../../../api/UK/paymentProvider/types';
import { queryKeys } from '../../../../../../../../api/utils/queryKeys';
import { CustomUseQueryOptions } from '../../../../../../../../types/reactQuery';

const usePaymentQuery = <TData = GetPaymentResponse>(
  options?: CustomUseQueryOptions<GetPaymentResponse, TData>
) =>
  useQuery({
    queryKey: queryKeys.UK.payment,
    queryFn: API.UK.paymentProvider.getPayment,
    ...options
  });

export default usePaymentQuery;
