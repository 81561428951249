import { create } from 'zustand';
import { ModalTypes } from '../components/ModalRenderer';

type OpenModalOptions = {
  payload?: ModalState['payload'];
  isPersistedFromRedirect?: ModalState['isPersistedFromRedirect'];
  confirmAction?: ModalState['confirmAction'];
  closeAction?: ModalState['closeAction'];
};

type ModalState = {
  showModal: boolean;
  type: ModalTypes | null;
  /* This is introduced to have the ability to keep a modal open after redirects because there is functionality in
   * history.ts to close the opened modal after each history update.
   */
  isPersistedFromRedirect: boolean;
  confirmAction?: () => void;
  closeAction?: () => void;
  payload?: unknown;

  openModal: (type: ModalTypes, options?: OpenModalOptions) => void;
  closeModal: () => void;
  closeNonPersistedModal: () => void;
  reset: () => void;
};

const initialState = {
  showModal: false,
  type: null,
  isPersistedFromRedirect: false
} satisfies Partial<ModalState>;

export const useModalStore = create<ModalState>()(set => ({
  ...initialState,
  openModal: (type, options) => set({ showModal: true, isPersistedFromRedirect: false, type, ...options }),
  closeModal: () => set(initialState),
  closeNonPersistedModal: () => set(state => (state.isPersistedFromRedirect ? state : initialState)),
  reset: () => set(initialState)
}));
