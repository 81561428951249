export enum AgreementStatus {
  APPLICATION = 'Application',
  SIGNED = 'Signed',
  SIGNING_FAILED = 'SigningFailed',
  GRANTED = 'Granted',
  REJECTED = 'Rejected',
  ELIGIBILITY_CHECKER_FAILED = 'ElligibilityCheckerFailed',
  LEVLA_OFFER = 'LevlaOffer',
  LEVLA_REJECTED = 'LevlaRejected',
  LEVLA_OFFER_SIGN_REQUEST = 'LevlaOfferSignRequest',
  ACCEPT_LEVLA_FAILED = 'AcceptLevlaFailed',
  LEVLA_SIGNING_FAILED = 'LevlaSigningFailed'
}
