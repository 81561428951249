import { Colors, device } from '@fairlo/ui-kit';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import { Container, zIndex } from '../../../styled';

export const Wrapper = styled.header<{ $isMenuOpen: boolean }>`
  position: relative;
  z-index: ${zIndex.HEADER};
  height: var(--header-height);
  background-color: ${({ $isMenuOpen }) =>
    $isMenuOpen ? Colors.WHITE : `var(--layout-background-color, transparent)`};
  transition: top 200ms cubic-bezier(0.4, 0, 1, 1);

  @media (${device.tablet}) {
    padding-right: var(--scrollbar-width);
  }
`;

export const Content = styled(Container)`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogotypeLink = styled(Link)`
  padding: 11px 0; // Used to increase tap target for touch devices
  line-height: 0;
`;

export const LogotypeImage = styled.img`
  height: 24px;

  @media (${device.tablet}) {
    height: 36px;
  }
`;
