import { Button, Stack } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import useTerminateCreditMutation from '../../../../pages/UserProfile/hooks/useTerminateCreditMutation';
import Textarea from '../../../Textarea';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';

type FormValues = {
  reason: string;
};

const ConfirmEndAgreement = () => {
  const { t } = useTranslation('Modal');

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<FormValues>({ mode: 'all', defaultValues: { reason: '' } });
  const { mutate: terminateCredit, isPending } = useTerminateCreditMutation();
  const onSubmit = ({ reason }: FormValues) => terminateCredit({ reason });

  return (
    <Modal title={t('ConfirmEndAgreement.title')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <ModalParagraph>{t('ConfirmEndAgreement.info')}</ModalParagraph>
          <ModalParagraph>{t('ConfirmEndAgreement.feedbackInfo')}</ModalParagraph>

          <Textarea
            {...register('reason', {
              required: t('FormErrors:required'),
              maxLength: { value: 300, message: t('FormErrors:maxLength', { length: 300 }) }
            })}
            aria-label={t('ConfirmEndAgreement.feedbackLabel')}
            placeholder={t('ConfirmEndAgreement.feedbackPlaceholder')}
            disabled={isPending}
            errorMessage={errors.reason?.message}
            rows={3}
          />
        </Stack>

        <Modal.Actions>
          <Button type="submit" color="alert" loading={isPending} disabled={!isValid}>
            {t('ConfirmEndAgreement.button')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};

export default ConfirmEndAgreement;
