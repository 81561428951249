import { Button, Modal } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

const PaymentFreeMonthWithdrawalBlocked = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Common']);

  return (
    <Modal title={t('PaymentFreeMonthWithdrawalBlocked.title')}>
      <ModalParagraph>{t('PaymentFreeMonthWithdrawalBlocked.info')}</ModalParagraph>
      <Modal.Actions>
        <Button onClick={closeModal}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PaymentFreeMonthWithdrawalBlocked;
