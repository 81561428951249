import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../../../../../hooks/useModal';
import { routeMap } from '../../../../../../../routes/routeMap';
import { useCreditFeaturesQuery } from '../../../../../hooks/UseFeaturesQueryMap';
import { selectWithdrawData } from '../../../../../hooks/UseFeaturesQueryMap/selectors';
import useCreditActionsQuery, { selectWithdrawAction } from '../../../../../hooks/useCreditActionsQuery';
import usePPIPromoModalCooldownPeriod from '../usePPIPromoModalCooldownPeriod';
import usePromotionsQuery from '../usePromotionsQuery';

const useWithdrawNavigation = () => {
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { getHasCooldownPeriod } = usePPIPromoModalCooldownPeriod();
  const { data: promotions, isLoading: isPromotionsLoading } = usePromotionsQuery();
  const { data: withdrawActions, isLoading: isCreditActionsLoading } = useCreditActionsQuery({
    select: selectWithdrawAction
  });
  const { data: withdrawData, isLoading: isWithdrawDataLoading } = useCreditFeaturesQuery({
    select: selectWithdrawData
  });
  const isWithdrawNavigationLoading = isPromotionsLoading || isCreditActionsLoading || isWithdrawDataLoading;
  const isPaymentProtectionInsurancePromoActive = promotions?.paymentProtectionInsurance.active;

  const navigateToWithdraw = useCallback(() => {
    if (isWithdrawNavigationLoading) {
      return;
    }

    const withdrawalBlockedReasons = withdrawActions?.disabled ? withdrawActions.reasons : [];

    if (withdrawalBlockedReasons.includes('Blocked')) {
      openModal('WITHDRAWAL_BLOCKED');
    } else if (withdrawalBlockedReasons.includes('UserGotPaymentFreeMonthRecently')) {
      openModal('SE.PAYMENT_FREE_MONTH_WITHDRAWAL_BLOCKED');
    } else if (withdrawalBlockedReasons.includes('MigrationToNewTierNeeded')) {
      openModal('SE.MIGRATION_TO_NEW_TIER_NEEDED');
    } else if (isPaymentProtectionInsurancePromoActive && !getHasCooldownPeriod()) {
      openModal('SE.PAYMENT_PROTECTION_INSURANCE_PROMO');
    } else if (withdrawData?.features.length === 0) {
      openModal('WITHDRAWAL_NOT_POSSIBLE', { confirmAction: closeModal });
    } else {
      navigate(routeMap.credit.withdrawal);
    }
  }, [
    closeModal,
    withdrawActions,
    getHasCooldownPeriod,
    isPaymentProtectionInsurancePromoActive,
    isWithdrawNavigationLoading,
    navigate,
    openModal,
    withdrawData?.features.length
  ]);

  return {
    navigateToWithdraw,
    isWithdrawNavigationLoading
  };
};

export default useWithdrawNavigation;
