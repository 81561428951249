import { environment } from '../utils/env.utils';

const { marketingSiteUrl: url } = environment;

const marketingSiteRouteMap = {
  home: url,
  contact: `${url}/contact`,
  faq: `${url}/faq`,
  termsAndConditions: `${url}/terms-and-conditions`,
  sekki: `${url}/sekki`,
  integrity: `${url}/integrity`,
  aboutFairlo: `${url}/about-fairlo`
};

export default marketingSiteRouteMap;
