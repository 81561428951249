import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeMap } from '../../routes/routeMap';
import { useAuthTokenActions } from '../../store/authTokenStore';
import { createRedirectToParam } from './helpers';

type Options =
  | {
      keepCurrentUrl?: false;
      redirectUrl?: string;
    }
  | {
      keepCurrentUrl: true;
      redirectUrl?: undefined;
    };

function useLogout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { removeAuthToken } = useAuthTokenActions();

  return useCallback(
    ({ keepCurrentUrl, redirectUrl }: Options = {}) => {
      removeAuthToken();
      queryClient.clear();

      let url = routeMap.login.root;
      if (keepCurrentUrl) {
        const redirectToParam = createRedirectToParam(location.pathname, location.search);
        if (redirectToParam) {
          url = `${url}?${redirectToParam}`;
        }
      } else if (redirectUrl) {
        url = redirectUrl;
      }

      navigate(url, { replace: true });
    },
    [location.pathname, location.search, navigate, queryClient, removeAuthToken]
  );
}

export default useLogout;
