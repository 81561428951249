import { QueryParam } from '../../constants';
import { routeMap } from '../../routes/routeMap';

export const createRedirectToParam = (pathname: string, search: string): string | null => {
  const currentUrlSearchParams = new URLSearchParams(search);
  currentUrlSearchParams.delete(QueryParam.AUTH_TOKEN);
  currentUrlSearchParams.delete(QueryParam.REDIRECT_TO);

  if (Array.from(currentUrlSearchParams.entries()).length === 0 && pathname === routeMap.home) {
    return null;
  }

  const searchParams = currentUrlSearchParams.toString();
  return new URLSearchParams({
    [QueryParam.REDIRECT_TO]: `${pathname}${searchParams ? `?${searchParams}` : ''}`
  }).toString();
};
