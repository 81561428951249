import { Button, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import Modal from '../../Modal';
import { ModalProps } from '../types';

const BankAccountNotSupported = ({ closeModal, confirmAction = closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Common']);

  return (
    <Modal title={t('BankAccountNotSupported.title')}>
      <Text>{t('BankAccountNotSupported.info')}</Text>
      <Modal.Actions>
        <Button onClick={confirmAction}>{t('Common:ok')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BankAccountNotSupported;
