import { Colors, device } from '@fairlo/ui-kit';
import { ToastContainer as ToastifyContainer } from 'react-toastify';
import styled from 'styled-components';

const ToastContainer = styled(ToastifyContainer)`
  &&&.Toastify__toast-container {
    position: fixed;
    top: 88px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 48px);
    max-width: 512px;
    padding: 0;

    @media (${device.tablet}) {
      top: 24px;
    }
  }
  .Toastify__toast {
    padding: 16px;
    border-radius: 8px;
    box-shadow: none;

    &.Toastify__toast--error {
      --toastify-color-light: ${Colors.RED_100};
    }
  }

  .Toastify__toast-body {
    color: ${Colors.TEXT};
    padding: 0;
  }

  .Toastify__toast-icon {
    align-self: flex-start;
  }
`;

export default ToastContainer;
