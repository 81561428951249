import axiosInstance from '../../utils/axiosInstance';
import {
  ApplicantEmploymentStatusPayload,
  ApplicantFinancesPayload,
  ApplicantPaymentProtectionInsurancePayload,
  ApplicantPersonalDetailsPayloadSE,
  ApplicantResponseSE
} from './types';

export * from './types';

export const createApplicant = async (payload: ApplicantPersonalDetailsPayloadSE) => {
  const response = await axiosInstance.post<ApplicantResponseSE>('/se/applicants', payload);
  return response.data;
};

/* Creates an applicant, with the information of the currently authenticated user */
export const createApplicantForAuthenticatedUser = async () => {
  const response = await axiosInstance.post<ApplicantResponseSE>('/se/applicants');
  return response.data;
};

export const updateApplicant = async (applicantId: string, payload: ApplicantPersonalDetailsPayloadSE) => {
  const response = await axiosInstance.put<ApplicantResponseSE>(`/se/applicants/${applicantId}`, payload);
  return response.data;
};

export const getApplicant = async (applicantId: string) => {
  const response = await axiosInstance.get<ApplicantResponseSE>(`/se/applicants/${applicantId}`);
  return response.data;
};

export const updateApplicantEmploymentStatus = async (
  applicantId: string,
  payload: ApplicantEmploymentStatusPayload
) => {
  const response = await axiosInstance.put<ApplicantResponseSE>(
    `/se/applicants/${applicantId}/employment-status`,
    payload
  );
  return response.data;
};

export const updateApplicantPaymentProtectionInsurance = async (
  applicantId: string,
  payload: ApplicantPaymentProtectionInsurancePayload
) => {
  const response = await axiosInstance.put<ApplicantResponseSE>(
    `/se/applicants/${applicantId}/payment-protection-insurance`,
    payload
  );
  return response.data;
};

export const updateApplicantFinances = async (applicantId: string, payload: ApplicantFinancesPayload) => {
  const response = await axiosInstance.put<ApplicantResponseSE>(
    `/se/applicants/${applicantId}/finances`,
    payload
  );
  return response.data;
};
