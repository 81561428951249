import { AnchorButton, Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import marketingSiteRouteMap from '../../../constants/marketingSiteRouteMap';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

const WithdrawalIpAddressCheck = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Common', 'Button']);

  return (
    <Modal title={t('WithdrawalIpAddressCheck.title')}>
      <ModalParagraph>{t('WithdrawalIpAddressCheck.info')}</ModalParagraph>
      <Modal.Actions>
        <AnchorButton href={marketingSiteRouteMap.contact} variant="outlined" color="neutral">
          {t('Common:contactUs')}
        </AnchorButton>
        <Button onClick={closeModal}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WithdrawalIpAddressCheck;
