export const LocalStorageKeys = {
  APPLICATION_STORE: 'fairlo.applicationStore',
  AUTH_STORE: 'fairlo.authStore',
  LAST_INITIATED_AUTOSTART_TOKEN: 'fairlo.lastInitiatedAutostartToken',
  DEVTOOLS: 'fairlo.devtools',
  DEVTOOLS_STORE: 'fairlo.devtools.store',
  LAST_COMPLETED_ORDER_ID: 'fairlo.lastCompletedOrderId',
  CREDIT_APPLICATION: 'fairlo.creditApplication',
  USER_ID: 'fairlo.userId'
} as const;
