export const queryKeys = {
  cms: {
    notices: ['cms', 'notices'],
    sekki: ['cms', 'sekki'],
    termsAndConditions: ['cms', 'termsAndConditions']
  },
  UK: {
    application: (applicationId: string) => ['application', applicationId],
    bankAccount: ['bankAccount'],
    bankAccountStatus: ['bankAccountStatus'],
    documents: ['documents'],
    loqateFind: (text: string, container = '') => ['loqateFind', text, container],
    loqateRetrieve: (id: string) => ['loqateRetrieve', id],
    marketingConsentText: ['marketingConsentText'],
    payment: ['payment'],
    paymentById: (paymentInitiationId: string) => ['paymentById', paymentInitiationId],
    directDebit: ['directDebit']
  },
  SE: {
    applyForCredit: (applicantId?: string, amount?: number) => ['applyForCredit', applicantId ?? '', amount],
    insurance: (token?: string) => ['insurance', token],
    bankIdStatus: (orderId: string) => ['bankIdStatus', orderId],
    loginData: (orderId?: string) => ['loginData', orderId ?? ''],
    application: (applicationId?: string) => ['application', applicationId]
  },
  creditApplicationStatus: (applicationStage: string, applicationId?: string) => [
    'creditApplicationStatus',
    applicationStage,
    applicationId ?? ''
  ],
  activeCredit: ['activeCredit'],
  overdueInfo: ['overdueInfo'],
  applicant: (applicantId: string) => ['applicant', applicantId],
  bankAccount: ['bankAccount'],
  creditFeatures: ['creditFeatures'],
  debtConsolidationFeatures: ['debtConsolidationFeatures'],
  directPaymentById: (directPaymentId: string) => ['directPaymentId', directPaymentId],
  directPaymentStatus: ['directPaymentStatus'],
  financialDetails: ['financialDetails'],
  lenders: ['lenders'],
  me: ['me'],
  payOverdue: ['payOverdue'],
  pendingTransaction: ['pendingTransaction'],
  planInfo: ['planInfo'],
  planInfoWithFeatures: ['planInfoWithFeatures'],
  products: ['products'],
  mobileVerification: ['mobileVerification'],
  usersMe: ['usersMe'],
  offers: ['offers'],
  withdrawalById: (transactionId: string) => ['withdrawalById', transactionId],
  creditActions: ['creditActions'],
  creditTierMigrationInfo: ['creditTierMigrationInfo'],
  promotions: ['promotions'],
  transactions: ['transactions']
};
