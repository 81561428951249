import { stegaClean } from '@sanity/client/stega';
import { AnchorHTMLAttributes, ComponentType, DetailedHTMLProps, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Slug } from '../integrations/sanity/sanity.types';
import { Nullable } from '../types/utils';
import { environment } from '../utils/env.utils';

const HtmlAnchor = (props: DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a {...props} />
);

export type CmsLinkProjectionType = Omit<Nullable<Link>, 'internal'> & {
  internal: {
    _type: string;
    slug: Slug | null;
  } | null;
};

export type CmsLinkProps = {
  anchorComponent?: ComponentType;
  linkComponent?: ComponentType;
  link?: CmsLinkProjectionType | null;
  children?: ReactNode;
  className?: string;
};

export const CmsLink = ({ link, children, anchorComponent, linkComponent, ...props }: CmsLinkProps) => {
  const Anchor = anchorComponent ?? HtmlAnchor;
  const Link = linkComponent ?? RouterLink;

  if (!link) {
    return null;
  }

  const linkType = stegaClean(link.type);
  const targetRelProps = link?.openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  if (linkType === 'email') {
    return (
      <Anchor href={`mailto:${link.email}`} {...props}>
        {children}
      </Anchor>
    );
  }

  if (linkType === 'external') {
    const url = link.queryParameters ? `${link.url}?${link.queryParameters}` : link.url;
    return (
      <Anchor href={url ?? undefined} {...targetRelProps} {...props}>
        {children}
      </Anchor>
    );
  }

  const slug =
    link.queryParameters && link.internal?.slug?.current
      ? `${link.internal.slug.current}?${link.queryParameters}`
      : link.internal?.slug?.current;

  if (link.internal?._type === 'clientPage') {
    return (
      <Link to={slug ?? '#'} {...targetRelProps} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <Anchor
      href={`${environment.marketingSiteUrl}/${slug}`}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}
    </Anchor>
  );
};
