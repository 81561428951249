import axiosInstance from '../utils/axiosInstance';
import { MarketingConsentPayload, MarketingConsentTextResponse } from './types';

export const getMarketingConsentText = async () => {
  const response = await axiosInstance.get<MarketingConsentTextResponse>('/marketing-consent/text');
  return response.data;
};

export const setMarketingConsent = async (payload: MarketingConsentPayload) => {
  const response = await axiosInstance.post<undefined>('/marketing-consent', payload);
  return response.data;
};
