import { ModalProps } from '../types';
import Modal from '../../Modal';
import { useTranslation } from 'react-i18next';
import { ModalParagraph } from '../shared';
import { Button } from '@fairlo/ui-kit';

const BankAccountUpdateInProgress = ({ closeModal, confirmAction = closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button']);

  return (
    <Modal title={t('BankAccountUpdateInProgress.title')}>
      <ModalParagraph>{t('BankAccountUpdateInProgress.info')}</ModalParagraph>
      <Modal.Actions>
        <Button onClick={confirmAction}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default BankAccountUpdateInProgress;
