import { ProfileFormModel } from '../../types/user';
import { reportException } from '../../utils/error';
import { ServerResponse } from '../types';
import { validateResponseData } from '../utils';
import axiosInstance from '../utils/axiosInstance';
import { getUserTransactionsResponseSchema } from './schemas';
import {
  AuthenticateWithTokenResponse,
  GetUserTransactionsResponse,
  TransactionDetail,
  TransactionDetailType,
  TransactionItem,
  TransactionItemsProduct
} from './types';

export const authenticateWithToken = async (authToken: string) => {
  const response = await axiosInstance.post<ServerResponse<AuthenticateWithTokenResponse>>(
    '/user/authenticate-token',
    { auth_token: authToken, personal_number: null }
  );
  return response.data.data;
};

export const updateUserDetails = async (userFields: ProfileFormModel) => {
  const response = await axiosInstance.patch<ServerResponse<ProfileFormModel>>('/user/update', userFields);
  return response.data.data;
};

export const getTransactionSuffix = (transaction: GetUserTransactionsResponse[number]) => {
  if (transaction.type !== 'DEPOSIT' && transaction.type !== 'WITHDRAW') {
    return null;
  }

  let suffix: 'CREDIT' | 'DEBT_CONSOLIDATION' | undefined;

  for (const item of transaction.items) {
    if (item.product === 'CREDIT' || item.product === 'DEBT_CONSOLIDATION') {
      suffix = item.product;
      break;
    }
  }

  if (!suffix) {
    reportException(new Error('No suffix found for transaction item', { cause: transaction }), {
      level: 'error',
      extra: { transaction }
    });
    return null;
  }

  return suffix;
};

const transactionDetailTypeOrder: TransactionDetailType[] = [
  'INTEREST',
  'PRINCIPAL',
  'WITHDRAWAL_FEE',
  'DIRECT_DEBIT_MISSING_FEE'
];

const compareBreakdownDetails = (a: TransactionDetail, b: TransactionDetail) =>
  transactionDetailTypeOrder.indexOf(a.type) - transactionDetailTypeOrder.indexOf(b.type);

const productOrder: TransactionItemsProduct[] = [
  'CREDIT',
  'DEBT_CONSOLIDATION',
  'PAYMENT_PROTECTION_INSURANCE'
];

const compareProductOrder = (a: TransactionItem, b: TransactionItem) =>
  productOrder.indexOf(a.product) - productOrder.indexOf(b.product);

export const transformTransactions = (transactions: GetUserTransactionsResponse) =>
  transactions.map(transaction => ({
    ...transaction,
    items: transaction.items.toSorted(compareProductOrder).map(item => ({
      ...item,
      details: item.details.toSorted(compareBreakdownDetails)
    })),
    suffix: getTransactionSuffix(transaction)
  }));

export const getUserTransactions = async () => {
  const response = await axiosInstance.get('/user/transactions');
  const transactions = validateResponseData(getUserTransactionsResponseSchema, response.data);
  return transformTransactions(transactions);
};
