import useRetryUntilResolved from '../hooks/useRetryUntilResolved';
import { environment } from '../utils/env.utils';

export const useInitializeSmartlookOptions = () => {
  useRetryUntilResolved(() => {
    // Smartlook is only available in production, skip this hook in development
    if (environment.env !== 'production') {
      return true;
    }

    if (!window?.smartlook) {
      return false;
    }

    window.smartlook('record', {
      forms: true,
      numbers: true
    });

    return true;
  });
};

export default useInitializeSmartlookOptions;
