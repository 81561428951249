import { useEffect } from 'react';

/**
 * Disables window scroll. When the scroll gets disabled, the scrollbar disappears, to prevent content shifting, padding
 * equivalent to the scrollbar is added.
 * This hooks also returns the scrollbarOffset for use by fixed elements.
 */
const useScrollLock = (enabled = true, delay = 0) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    let timeout: NodeJS.Timeout | undefined;
    timeout = setTimeout(() => {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

      document.body.style.overflow = 'hidden'; // Disables scrolling
      document.documentElement.style.paddingRight = `${scrollbarWidth}px`;
      document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    }, delay);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }

      setTimeout(() => {
        document.body.style.overflow = '';
        document.documentElement.style.paddingRight = '';
        // if the component unmounts setScrollbar offset will not exist
        if (Number(document.documentElement.style.getPropertyValue('--scrollbar-width')) !== 0) {
          document.documentElement.style.setProperty('--scrollbar-width', '0');
        }
      }, delay);
    };
  }, [enabled, delay]);
};

export default useScrollLock;
