import { validateResponseData } from '../../utils';
import axiosInstance from '../../utils/axiosInstance';
import { bankIdRetryResponseSchema, bankIdStatusResponseSchema } from './schemas';

export const getBankIdStatus = async (orderId: string) => {
  const response = await axiosInstance.get(`/bankid/${orderId}/status?qr-code=true`);

  return validateResponseData(bankIdStatusResponseSchema, response.data);
};

export const retryBankId = async (orderId: string) => {
  const response = await axiosInstance.post(`/bankid/${orderId}/retry`);

  return validateResponseData(bankIdRetryResponseSchema, response.data);
};
