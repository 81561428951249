import { Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

const DebtConsolidationDirectDebitMandateNotFound = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button']);

  return (
    <Modal title={t('DebtConsolidationDirectDebitMandateNotFound.title')}>
      <ModalParagraph>{t('DebtConsolidationDirectDebitMandateNotFound.info')}</ModalParagraph>
      <Modal.Actions>
        <Button onClick={closeModal}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DebtConsolidationDirectDebitMandateNotFound;
