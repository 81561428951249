import { ApplicationStatus } from '../../../constants/UK';
import { ApplicationRejectReasons } from '../../../constants/UK/rejectReasons';

export type CreateApplicationResponse = {
  id: string;
};

export type ProductTier = {
  id: string;
  amount: number;
  effectiveInterest: number;
  interest: number;
  monthlyPayment: number;
  period: number;
  totalAmount: number;
  totalCost: number;
};

type ApplicationBaseResponse = {
  id: string;
};

export type ApplicationPendingResponse = ApplicationBaseResponse & {
  status: ApplicationStatus.PENDING;
};

export type ApplicationRejectedResponse = ApplicationBaseResponse & {
  status: ApplicationStatus.REJECTED;
  reason: ApplicationRejectReasons;
};

export enum ApplicationManualApprovalReasons {
  NOTICE_OF_CORRECTION = 'noticeOfCorrection',
  POLITICALLY_EXPOSED_PERSON = 'politicallyExposedPerson',
  SELF_EMPLOYED_INCOME_VERIFICATION = 'selfEmployedIncomeVerification',
  PART_TIME_VARIABLE_INCOME = 'partTimeVariableIncome'
}

export type ApplicationManualApprovalResponse = ApplicationBaseResponse & {
  status: ApplicationStatus.MANUAL_APPROVAL;
  reasons: ApplicationManualApprovalReasons[];
};

export type ApplicationResponse =
  | ApplicationPendingResponse
  | ApplicationRejectedResponse
  | ApplicationManualApprovalResponse
  | ApplicationApprovedResponse;

export type ApplicationApprovedResponse = ApplicationBaseResponse & {
  status: ApplicationStatus.APPROVED;
  offeredProductTiers: ProductTier[];
  authToken: string;
};
type DocumentStatus = 'signed' | 'unsigned';

export type DocumentsResponse = {
  summary: DocumentStatus;
  creditInformation: DocumentStatus;
  creditAgreement: DocumentStatus;
  termsAndConditions: DocumentStatus;
};

export type DocumentName = keyof DocumentsResponse;
