import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { UtmTagData, storeTrackingData } from '../tracking';

const useStoreTrackingData = () => {
  const location = useLocation();

  useEffect(
    function saveTrackingData() {
      const queryParams = new URLSearchParams(location.search);

      const purchaseTrackingId = queryParams.get('at_gd') ?? queryParams.get('coid') ?? undefined;

      let utmTags: UtmTagData | undefined;

      if (queryParams.has('utm_source') || queryParams.has('utm_medium') || queryParams.has('utm_campaign')) {
        const { utm_source, utm_medium, utm_campaign, utm_term, utm_content, ...restParams } =
          Object.fromEntries(queryParams);

        let utm_custom_tags: UtmTagData['utm_custom_tags'];

        Object.keys(restParams)
          .filter(queryKey => queryKey.startsWith('utm_'))
          .forEach(queryKey => {
            const value = queryParams.get(queryKey);
            if (value) {
              if (!utm_custom_tags) {
                utm_custom_tags = {};
              }

              utm_custom_tags[queryKey] = value;
            }
          });

        utmTags = {
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
          utm_custom_tags
        };
      }

      storeTrackingData({
        purchaseTrackingId,
        utmTags
      });
    },
    [location.search]
  );
};

export default useStoreTrackingData;
