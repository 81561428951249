import { AnchorButton, Button } from '@fairlo/ui-kit';
import { Trans, useTranslation } from 'react-i18next';

import marketingSiteRouteMap from '../../../constants/marketingSiteRouteMap';
import useMeQuery from '../../../hooks/useMeQuery';
import { selectUsername } from '../../../hooks/useMeQuery/selectors';
import Modal from '../Modal';
import { ModalParagraph } from './shared';
import { ModalProps } from './types';

const WithdrawalBlocked = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Common', 'Button']);
  const { data: username } = useMeQuery({ select: selectUsername });

  return (
    <Modal
      title={
        <Trans
          t={t}
          i18nKey="WithdrawalBlocked.title"
          values={{ username }}
          components={{ private: <span data-private /> }}
        />
      }
    >
      <ModalParagraph>{t('WithdrawalBlocked.info')}</ModalParagraph>
      <Modal.Actions>
        <AnchorButton href={marketingSiteRouteMap.contact} variant="outlined" color="neutral">
          {t('Common:contactUs')}
        </AnchorButton>
        <Button onClick={closeModal}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WithdrawalBlocked;
