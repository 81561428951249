import { useQuery } from '@tanstack/react-query';
import API, { BankAccountResponse } from '../api';
import { queryKeys } from '../api/utils/queryKeys';
import { constants } from '../constants';
import { CustomUseQueryOptions } from '../types/reactQuery';

const useBankAccountQuery = <TData = BankAccountResponse>(
  options?: CustomUseQueryOptions<BankAccountResponse, TData>
) =>
  useQuery({
    queryKey: queryKeys.bankAccount,
    queryFn: API.me.getBankAccount,
    staleTime: constants.EXTENDED_STALE_TIME,
    ...options
  });

export default useBankAccountQuery;
