import groq from 'groq';
import { sanityFetch } from '../client';
import { SEKKI_QUERYResult } from '../sanity.types';
import { CONTENT_BLOCK_GROUP_PROJECTION } from './projections';

const SEKKI_QUERY = groq`*[_type == "sekkiContent"][0] {
  id,
  _type,
  contentBlockGroup ${CONTENT_BLOCK_GROUP_PROJECTION} 
}`;

export const getSekki = () => sanityFetch<SEKKI_QUERYResult>(SEKKI_QUERY);
