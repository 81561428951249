import { Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

const ConfirmManualDeposit = ({ closeModal }: ModalProps) => {
  const { t } = useTranslation(['Modal', 'Button']);

  return (
    <Modal title={t('ConfirmManualDeposit.title')}>
      <ModalParagraph>{t('ConfirmManualDeposit.info')}</ModalParagraph>

      <Modal.Actions>
        <Button onClick={closeModal}>{t('Button:clear')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmManualDeposit;
