import { Button } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import useBankAccountQuery from '../../../../hooks/useBankAccountQuery';
import { routeMap } from '../../../../routes/routeMap';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { ModalProps } from '../types';

type Payload = {
  transactionId: string;
};

const WithdrawalSuccess = ({ payload, closeModal }: ModalProps<Payload>) => {
  const { t } = useTranslation('Modal');
  const { data: bankAccount } = useBankAccountQuery();
  const navigate = useNavigate();

  return (
    <Modal title={t('WithdrawalSuccess.title')}>
      <ModalParagraph>
        {bankAccount?.directBank ? t('WithdrawalSuccess.directPayoutInfo') : t('WithdrawalSuccess.info')}
      </ModalParagraph>
      <Modal.Actions>
        <Button
          variant="outlined"
          color="neutral"
          onClick={() => {
            closeModal();
            navigate(routeMap.getFairnessReceiptById(payload.transactionId));
          }}
        >
          {t('WithdrawalSuccess.fairnessReceiptButton')}
        </Button>
        <Button onClick={closeModal}>{t('WithdrawalSuccess.button')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WithdrawalSuccess;
