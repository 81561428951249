import { Button, Text } from '@fairlo/ui-kit';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { ModalProps } from '../types';

const ConfirmTerminatePaymentProtectionInsurance = ({ confirmAction }: ModalProps) => {
  const { t } = useTranslation('Modal', { keyPrefix: 'ConfirmTerminatePaymentProtectionInsurance' });

  return (
    <Modal title={t('title')}>
      <Text>{t('info')}</Text>
      <Modal.Actions>
        <Button
          onClick={() => {
            if (confirmAction) {
              confirmAction();
            }
          }}
        >
          {t('button')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmTerminatePaymentProtectionInsurance;
