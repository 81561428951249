import { RefObject, useCallback, useEffect } from 'react';

type Options = {
  enabled?: boolean;
};

const useOnClickOutside = (
  callback: (evt: PointerEvent) => void,
  refs: RefObject<HTMLElement>[],
  options?: Options
) => {
  const { enabled }: Options = {
    enabled: true,
    ...options
  };

  const handleClickOutside = useCallback(
    (e: PointerEvent) => {
      const target = e.target as HTMLElement;

      const isTargetInsideRefs = refs.some(ref => {
        if (!ref.current) {
          return false;
        }

        return ref.current.contains(target);
      });

      if (!isTargetInsideRefs) {
        callback(e);
      }
    },
    [refs, callback]
  );

  useEffect(() => {
    if (enabled) {
      document.addEventListener('pointerup', handleClickOutside);
    }

    return () => {
      document.removeEventListener('pointerup', handleClickOutside);
    };
  }, [enabled, handleClickOutside]);
};

export default useOnClickOutside;
