import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { REDIRECT_INFO_DISPLAY_TIME } from '../../constants/constants';
import Loader from '../Loader';

type Props = {
  url: string;
};

const PaymentProvider = ({ url }: Props) => {
  const { t } = useTranslation('PaymentProvider');

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = url;
    }, REDIRECT_INFO_DISPLAY_TIME);

    return () => {
      clearTimeout(timeout);
    };
  }, [url]);

  return <Loader fullscreen text={t('willRedirect')} />;
};

export default PaymentProvider;
