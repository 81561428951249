import { Stack } from '@fairlo/ui-kit';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../../api/utils/queryKeys';
import { cmsApi } from '../../../../integrations/sanity/api';
import TermsAndConditionsAccordion from '../../../UK/credit/TermsAndConditionsAccordion';
import Modal from '../../Modal';
import { ModalParagraph } from '../shared';
import { modalAccordionStyles } from '../styled';

const StyledTermsAndConditions = styled(TermsAndConditionsAccordion)`
  ${modalAccordionStyles};
`;

const TermsAndConditions = () => {
  const { data: termsAndConditionsData } = useQuery({
    queryKey: queryKeys.cms.termsAndConditions,
    queryFn: cmsApi.getTermsAndConditions,
    staleTime: Infinity
  });

  return (
    <Modal title="Terms and Conditions">
      <Stack spacing={24}>
        <ModalParagraph>{termsAndConditionsData?.lastUpdatedTitle}</ModalParagraph>

        <StyledTermsAndConditions />
      </Stack>
    </Modal>
  );
};

export default TermsAndConditions;
