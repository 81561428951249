import { Button, Stack, Text } from '@fairlo/ui-kit';
import styled from 'styled-components';

import Modal from '../../Modal';

import { ModalListItem, ModalParagraph } from '../shared';
import { ModalProps } from '../types';

const StyledList = styled.ul`
  margin-top: 0;
`;

const BankAccountManualApproval = ({ closeModal }: ModalProps) => (
  <Modal title="We need a few more details">
    <Stack>
      <ModalParagraph>We’re sorry, but we haven’t been able to verify your nominated account.</ModalParagraph>
      <Stack spacing={8}>
        <Text as="span">Your nominated account must be:</Text>
        <StyledList>
          <ModalListItem>A UK bank or building society account.</ModalListItem>
          <ModalListItem>Held in your name and registered at your residential address.</ModalListItem>
          <ModalListItem>Able to receive electronic payments.</ModalListItem>
        </StyledList>
      </Stack>
      <Stack spacing={8}>
        <ModalParagraph>
          Please can you reply to the email we have just sent to you with the following so that we can verify
          the account belongs to you:
        </ModalParagraph>
        <StyledList>
          <ModalListItem>
            A statement from the last 3 months showing all the account’s details including your name, address,
            sort code and account number
          </ModalListItem>
          <ModalListItem>
            A picture of the front of your driving license, or passport picture page
          </ModalListItem>
        </StyledList>
      </Stack>
    </Stack>
    <Modal.Actions>
      <Button onClick={closeModal}>Got it</Button>
    </Modal.Actions>
  </Modal>
);

export default BankAccountManualApproval;
