import { lazy, LazyExoticComponent } from 'react';
import { Country } from '../../localization/constants';
import { environment } from '../../utils/env.utils';

const ChatWidgetScriptCountryMap: Record<Country, LazyExoticComponent<() => JSX.Element>> = {
  SE: lazy(() => import('../../integrations/chat/ZendeskWidgetScript')),
  UK: lazy(() => import('../../integrations/chat/FreshdeskWidgetScript'))
};

const ChatWidgetScript = () => {
  const DynamicChatWidgetScript = ChatWidgetScriptCountryMap[environment.country];

  return <DynamicChatWidgetScript />;
};

export default ChatWidgetScript;
