import { FinancialData } from '../../types/credit';
import axiosInstance from '../utils/axiosInstance';
import { BankAccountResponse, MeResponse } from './types';

export const getMe = async () => {
  const response = await axiosInstance.get<MeResponse>('/me');
  return response.data;
};

export const getFinancialDetails = async () => {
  const response = await axiosInstance.get<FinancialData>('/me/kalp');
  return response.data;
};

export const updateFinancialDetails = async (data: FinancialData) => {
  const response = await axiosInstance.put<FinancialData>('/me/kalp', data);
  return response.data;
};

export const getBankAccount = async () => {
  const response = await axiosInstance.get<BankAccountResponse>('/me/bank-account');
  return response.data;
};
